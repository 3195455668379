import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { CACHE_KEY } from '../config/config.constants';
import * as moment from 'moment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseApi = '';
  baseApiInfringementReprocessing = ''

  cacheHeader = 'x-local-cache-ttl';

  constructor(
    private http: HttpClient,
    private http2: HttpClient,

    private KeycloakService: KeycloakService,
    private handler: HttpBackend,
    private authService: AuthService
  ) {
    this.baseApi = environment.baseApi;
    this.baseApiInfringementReprocessing = environment.baseApiInfringementReprocessing;
    this.KeycloakService = KeycloakService;

  }

  get(url: string) {
    return this.http.get(this.baseApi + url);
  }

  getInfringementReprocessing(url: string) {
    return this.http.get(this.baseApiInfringementReprocessing + url);
  }

  postInfringementReprocessing(url: string, params?: any) {
    return this.http.post(this.baseApiInfringementReprocessing + url, params);
  }

  getDownload(url: string) {
    return this.http.get(this.baseApi + url, {
      headers: {
        Accept: 'application/octet-stream'
      },
      responseType: 'arraybuffer',
      observe: 'response'
    });
  }

  postDownload(url: string, params?: any) {
    return this.http.post(this.baseApi + url, params,{
      headers: {
        Accept: 'application/octet-stream'
      },
      responseType: 'arraybuffer',
      observe: 'response'
    });
  }

  async getCachedWithRevalidate(url: string) {
    const cache = await caches.open(CACHE_KEY);
    const response = await cache.match(url);
    if (
      !!response &&
      Number(response.headers.get(this.cacheHeader)) > moment().unix()
    ) {
      this.getAndCacheIfOk(url);
      return response.json();
    }

    return (await this.getAndCacheIfOk(url)).json();
  }

  private async getAndCacheIfOk(url: string) {
    if (this.KeycloakService.isTokenExpired()) {
      await this.KeycloakService.updateToken();
    }
    var headers = new Headers({
      Authorization: `Bearer ${
        this.KeycloakService.getKeycloakInstance().token
      }`
    });
    let tokenImpersonate = sessionStorage.getItem('tokenLoginAs');
    if (tokenImpersonate) {
      headers.append('Authorization-Impersonate', `Bearer ${tokenImpersonate}`);
    }
    const response = await fetch(this.baseApi + url, {
      headers: headers
    });
    if (response.ok) {
      const responseClone = response.clone();
      const newHeaders = new Headers(responseClone.headers);
      newHeaders.append(
        this.cacheHeader,
        moment().add(environment.cacheMinutes, 'minutes').unix().toString()
      );
      const responseToCache = new Response(responseClone.body, {
        headers: newHeaders
      });
      const cache = await caches.open(CACHE_KEY);
      await cache.put(url, responseToCache);
    }
    return response;
  }

  getWithoutBase(url: string): Observable<Object> {
    return this.http.get(url);
  }

  post(url: string, params?: any, header?: any) {
    if (header) {
      return this.http.post(this.baseApi + url, params, { headers: header });
    } else {
      return this.http.post(this.baseApi + url, params);
    }
  }

  put(url: string, params?: any) {
    return this.http.put(this.baseApi + url, params);
  }

  delete(url: string, params?: any) {
    return this.http.delete(this.baseApi + url);
  }

  patch(url: string, params?: any) {
    return this.http.patch(this.baseApi + url, params);
  }

  downloadFile(url: string): Observable<any> {
    //#FZ eliminato header e quindi Authorization: Bearer
    // e creato http2 in modo da avere gestione separate da altre call che devonoa avere token
    this.http2 = new HttpClient(this.handler);
    return this.http2.get(url, {
      // headers: {
      //   Accept: 'application/octet-stream'
      // },
       responseType: 'arraybuffer'
    });
  }

  downloadRegulation(url: string): Observable<any> {
    return this.http.get(this.baseApi + url, {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'arraybuffer'
    });
  }

  getDDDsContent(url: string, params?: any) {
      return this.http.post(this.baseApi + url, params, {
        headers: {
          Accept: 'application/octet-stream'
        },
        responseType: 'arraybuffer',
        observe: 'response'
      });
  }

  async isTokenExpiredAndRefresh(){
    // FZ add try catch to intercept token expire and redirect to logout()
    try {
      if (this.KeycloakService.isTokenExpired()) {
        await this.KeycloakService.updateToken();
      }
      // var headers = new Headers({
      //   Authorization: `Bearer ${
      //     this.KeycloakService.getKeycloakInstance().token
      //   }`
      // });
      var headers = new Headers;
      headers.set('Authorization',`Bearer ${
           this.KeycloakService.getKeycloakInstance().token
           }`)
      headers.set('Cache-Control', 'no-cache')
      headers.set('Pragma', 'no-cache')
      return headers
    } catch (error) {
      console.log(error)
       this.authService.logOut();

    }

  }


  postRecalcRequest(url: string, params?: any) {
    return this.http.post(this.baseApi + url, params);
  }


}
