<div class="card">
    <h4 class="card-header">{{"global.modal.confirmation" | translate}}</h4>
    <div class="card-body">
      <p class="card-text">{{ data | translate}}</p>
    </div>


      <div class="flex justify-end p-2">
        <div>
           <button pButton pRipple type="button" (click)="save()">{{"global.modal.yes" | translate}}</button>
        </div>
        <div class="pl-2">
          <button pButton pRipple type="button" (click)="close()" class="p-button-warning pl-2"> {{"global.modal.no" | translate}}</button>
        </div>

      </div>



</div>
