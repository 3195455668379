
<nav class="navbar-default aft-header__navbar d-flex" >
  <div class="header-left">
      <!-- btn toggle sidebar -->
      <a (click)="toggleNav()" id="menu-toggle-sidebar" class="navbar-toggle aft-header__sidebar-toggle"
      pTooltip="{{'global.toggleMenu' | translate }}" tooltipPosition="right">
          <fa-icon [icon]="faBars"></fa-icon>
      </a>

      <!-- logo -->
      <div class="{{stylesheetStringHeaderComponent}}-header__logo__wrapper"
      pTooltip="{{'global.version' | translate}} {{version}}" tooltipPosition="bottom"
      *ngIf="stylesheetStringHeaderComponent">
          <a class="aft-header__logo__link" [routerLink]="['/app/home']">
              <div class="{{stylesheetStringHeaderComponent}}-header__logo" >
              </div>

          </a>
      </div>



  </div>

    <div class="flex justify-center items-center w-42 ml-10 "  *ngIf="!stylesheetStringHeaderComponent">

      <a  [routerLink]="['/app/home']" class="navbar-toggle aft-header__sidebar-toggle">
        <div
        pTooltip="{{'global.home' | translate }}" tooltipPosition="right">
          <i class="fa fa-home"></i>
          <span class="pl-2">Home</span>
        </div>

    </a>
    </div>
    <!-- <div>
      <app-filter></app-filter>
    </div> -->


    <div class="flex flex-row  items-center"  #navHeaderFilterRef>
      <app-filter-ref></app-filter-ref>
    </div>

  <!-- <app-filter-new></app-filter-new> -->


  <!-- right header -->
  <div class="header-right d-flex">

      <ul [ngClass]="{'navbar-nav': !impersonating}" class="nav user-header">
          <li *ngIf="impersonating" class="aft-header__impersonating">
              <span>
                  <span class="text-danger">{{'global.emulatingUser' | translate}}</span>
              </span>
          </li>

            <li *ngIf="demoMode" class="aft-header__impersonating">
                <span class="text-info">
                    <b>DEMO</b>
                </span>
            </li>
            <a role="button" id="dropdownBasic1" (click)="opUser.toggle($event)">
              <fa-icon [icon]="faUser" [class.text-danger]="impersonating"></fa-icon>
              <span class="caret" [class.text-danger]="impersonating"></span>
            </a>

            <!-- <li ngbDropdown class="dropdown">
                <a role="button" id="dropdownBasic1" ngbDropdownToggle>
                    <fa-icon [icon]="faUser" [class.text-danger]="impersonating"></fa-icon>
                    <span class="caret" [class.text-danger]="impersonating"></span>
                </a>
                <ul role="menu" class="dropdown-menu {{stylesheetString}}-header__dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <li ngbDropdownItem><a><b [class.text-danger]="impersonating"> {{user?.firstName}} </b></a></li>
                    <li ngbDropdownItem [routerLink]="['/app/preferences']">
                        <a>
                            <fa-icon [icon]="faCog"></fa-icon>
                            <span>{{'home.preferences' | translate}}</span>
                        </a>
                    </li>
                    <li ngbDropdownItem *ngIf="!impersonating" class="aft-customhide" (click)="confirmLogout()">
                        <a>
                            <fa-icon [icon]="faPowerOff"></fa-icon>
                            <span>{{'home.logout' | translate}}</span>
                        </a>
                    </li>
                    <li ngbDropdownItem *ngIf="impersonating" (click)="confirmLogoutImpersonatedUser()">
                        <a>
                            <fa-icon [icon]="faPowerOff" class="text-danger"></fa-icon>
                            <span style="color: red;">{{'global.terminateLoginAsSession' | translate}}</span>
                        </a>
                    </li>
                </ul>
            </li> -->
        </ul>
        <!--btn alert-->
        <div *odngIf="hasAlerting" [ngClass]="{'alert-header': !impersonating, 'alert-header-impersonating': impersonating}"
        pTooltip="{{'global.alert' | translate }}" tooltipPosition="left">
            <div class="aft-display-inline-block" [routerLink]="['/app/alertreport']">
                <fa-icon [icon]="faBell"></fa-icon>
            </div>
            <div *ngIf="alertingService.getAlertNotReads() > 0" class="aft-display-inline-block">
                <span class="badge aft-header-alert-badge">{{ alertingService.getAlertNotReads() }}</span>
            </div>
        </div>
    </div>
</nav>

<p-overlayPanel #opUser [showCloseIcon]="false" [dismissable]="true" class="pb-0 mb-0" >
  <ng-template pTemplate>
    <ul class="list-none p-1 mb-0">
      <li class="pt-0 pb-1 text-center tracking-wide text-cyan-700 ">
        <a><b [class.text-danger]="impersonating"> {{user?.firstName}} </b></a>
      </li>
      <p-divider></p-divider>
      <li  [routerLink]="['/app/preferences']" class="pt-3 cursor-pointer" >
        <a (click) = "toogleMenu()">
            <fa-icon [icon]="faCog" class="pr-1"></fa-icon>
            <span>{{'home.preferences' | translate}}</span>
        </a>
    </li>
    <li  *ngIf="!impersonating" class="aft-customhide" (click)="confirmLogout()" class="pt-2 cursor-pointer">
      <a>
          <fa-icon [icon]="faPowerOff" class="pr-1"></fa-icon>
          <span>{{'home.logout' | translate}}</span>
      </a>
  </li>
  <li  *ngIf="impersonating" (click)="confirmLogoutImpersonatedUser()" class="pt-2 cursor-pointer">
    <a>
        <fa-icon [icon]="faPowerOff" class="text-danger pr-1"  ></fa-icon>
        <span style="color: red;">{{'global.terminateLoginAsSession' | translate}}</span>
    </a>
</li>
    </ul>

  </ng-template>
</p-overlayPanel>
