import { DataService } from 'src/app/core/services/data.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterService } from './shared/components/filter/filter.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UtilsService } from './core/services/utils.service';
import { Subscription } from 'rxjs';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
declare const gtag: Function;
export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  private _cookieClicked: string | null;
  constructor(
    private translate: TranslateService,
    private dataService: DataService,
    private utilsService: UtilsService,
    private router: Router,
    // private filterService: FilterService,
    private ccService: NgcCookieConsentService,) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-721XSB9HN6', { 'page_path': event.urlAfterRedirects });
      }
    })

    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnInit() {
    this.translate.setDefaultLang(this.dataService.getLanguage());
    this.utilsService.setHostName(location.host);
    this.setFavicon();
    this.setTitle();
    this.router.events.subscribe(event => this.closeFilterOnNavigation(event));
    this._cookieClicked = localStorage.getItem('cookieClicked');
    if (this._cookieClicked == 'true') {
      this.ccService.destroy();
    }
  }

  setFavicon() {
    var headID = document.getElementsByTagName('head')[0];
    var link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    let host = location.host;
    if (host.indexOf('web.dms.srl') !== -1)
      link.href = '../assets/styles/customers/' + host + "/images/favicon.png";
    else if (host.indexOf('sctacho.recorditalia.net') !== -1)
      link.href = '../assets/styles/customers/' + host + "/images/favicon.png";
    else if (host.indexOf('geo.geofleet.it') !== -1)
      link.href = '../assets/styles/customers/' + host + "/images/favicon.png";
    else if (host.indexOf('tacho.trackposition.info') !== -1)
      link.href = '../assets/styles/customers/' + host + "/images/favicon.png";
    else if (host.indexOf('chrono.fleetcontrol.it') !== -1)
      link.href = '../assets/styles/customers/' + host + "/images/favicon.png";
    else if (host.indexOf('fleetmgmt.intellic.com') !== -1)
      link.href = '../assets/styles/customers/' + host + "/images/favicon.png";
    else if (host.indexOf('chronofleet.siaksolution.it') !== -1)
      link.href = '../assets/styles/customers/' + host + "/images/favicon.png";
    else if (host.indexOf('flotte.ddrspa.com') !== -1)
      link.href = '../assets/styles/customers/' + host + "/images/favicon.png";
    else if (host.indexOf('fleet.wortach.com') !== -1)
      link.href = '../assets/styles/customers/' + host + "/images/favicon.png";
    else
    link.href = '../assets/images/favicon.png';
    headID.appendChild(link);
  }

  setTitle() {
    let titleString = this.getTitleString();
    let headID = document.getElementsByTagName('head')[0];
    let title = document.createElement('title');
    title.textContent = titleString;
    headID.appendChild(title);
  }

  getTitleString() {
    let host = location.host;
    if (host.indexOf('web.dms.srl') !== -1)
      return 'DMS';
    else if (host.indexOf('sctacho.recorditalia.net') !== -1)
      return 'SCTacho';
    else if (host.indexOf('geo.geofleet.it') !== -1)
      return 'GEOFleet';
    else if (host.indexOf('tacho.trackposition.info') !== -1)
      return 'TrackPosition Tacho';
    else if (host.indexOf('chrono.fleetcontrol.it') !== -1)
      return 'FleetControl';
    else if (host.indexOf('fleetmgmt.intellic.com') !== -1)
      return 'Fleet management';
    else if (host.indexOf('chronofleet.siaksolution.it') !== -1)
      return 'Chrono Fleet - Siak Solution';
    else if (host.indexOf('flotte.ddrspa.com') !== -1)
      return 'DIERRE Flotte';
    else if (host.indexOf('fleet.wortach.com') !== -1)
      return 'Fleet Wortach';
    else
      return 'ACTIAFleet';
  }



  closeFilterOnNavigation(event) {
    if (event instanceof NavigationEnd) {
      if (!event.url.includes("/auth")) {
        // if (this.filterService.isFilterOpen()) {
        //   (document.querySelector(".filter-body") as HTMLElement).style.display = "none";
        //   this.filterService.closeFilter();
        // }
      }
    }
  }
}
