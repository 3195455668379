import { createSelector } from "@ngrx/store";
import { AppStateInterface } from "src/app/appState.interface";
import { ListTabRefInterface, TabFilterRefInterface } from "./interface/tabFilterRef.interface";


export const selectCustomersFeature = (state:AppStateInterface) => state.getCustomers;
export const selectVehicleFeature = (state:AppStateInterface) => state.getVehiclesOfCustomers;
export const selectFleetFeature = (state:AppStateInterface) => state.getFleetsOfCustomers;
export const selectDriverFeature = (state:AppStateInterface) => state.getDriversOfCustomers;
export const selectTeamsFeature = (state:AppStateInterface) => state.getTeamsOfCustomers;
export const selectSelectedCustomerFeature = (state:AppStateInterface) => state.setSelectedCustomerCache;
export const setTabFilterRefFeature = (state:ListTabRefInterface) => state;
export const selectTabFilterRefFeature = (state:AppStateInterface) => state.setTabFilterRef;
export const selectTabAssetChooserRefFeature = (state:AppStateInterface) => state.setTabAssetChooserRef;
export const selectUserFeature = (state:AppStateInterface) => state.user;
export const selectApplySelectedUidCustomer = (state:AppStateInterface) =>state.applySelectedCustomer;
export const selectSelectedAssets = (state:AppStateInterface) => state.setSelectedAssets;
export const selectApplySelectedAssets = (state:AppStateInterface) => state.applySelectedAssets;
export const selectSelectedPeriod = (state:AppStateInterface) => state.setSelectedPeriod;
export const selectApplySelectedPeriod = (state:AppStateInterface) => state.applySelectedPeriod;
export const selectCompleteState = (state:AppStateInterface) => state.completeApplyState;
export const selectFilterRefSetting = (state:AppStateInterface) => state.filterRefSetting;

//Customers
 export const isLoadingCustomersSelector = createSelector(
  selectCustomersFeature,
  (state) => state.isLoading
 )

 export const getCustomersSelector = createSelector(
  selectCustomersFeature,
   (state) => state.posts
  )

  export const errorCustomersSelector = createSelector(
    selectCustomersFeature,
   (state) => state.error
  )

  // Vehicle of Customers
 export const isLoadingVehiclesOfCustomersSelector = createSelector(
  selectVehicleFeature,
  (state) => state.isLoading
 )

 export const getVehiclesOfCustomersSelector = createSelector(
  selectVehicleFeature,
   (state) => state.posts
  )

  export const errorVehiclesOfCustomersSelector = createSelector(
    selectVehicleFeature,
   (state) => state.error
  )


  // Fleet of Customers
 export const isLoadingFleetsOfCustomersSelector = createSelector(
  selectFleetFeature,
  (state) => state.isLoading
 )

 export const getFleetsOfCustomersSelector = createSelector(
  selectFleetFeature,
   (state) => state.posts
  )

  export const errorFleetsOfCustomersSelector = createSelector(
    selectFleetFeature,
   (state) => state.error
  )

   // Driver of Customers
 export const isLoadingDriversOfCustomersSelector = createSelector(
  selectDriverFeature,
  (state) => state.isLoading
 )

 export const getDriversOfCustomersSelector = createSelector(
  selectDriverFeature,
   (state) => state.posts
  )

  export const errorDriversOfCustomersSelector = createSelector(
    selectDriverFeature,
   (state) => state.error
  )

   // Teams of Customers
 export const isLoadingTeamsOfCustomersSelector = createSelector(
  selectTeamsFeature,
  (state) => state.isLoading
 )

 export const getTeamsOfCustomersSelector = createSelector(
  selectTeamsFeature,
   (state) => state.posts
  )

  export const errorTeamsOfCustomersSelector = createSelector(
    selectTeamsFeature,
   (state) => state.error
  )

  //Selected Customer
  export const selectedCustomerNameSelector = createSelector(
    selectSelectedCustomerFeature,
    (state) => state.name
   )


   export const selectedCustomerUidSelector = createSelector(
    selectSelectedCustomerFeature,
    (state) => state.uid
   )

   export const selectedCustomerSelector = createSelector(
    selectSelectedCustomerFeature,
    (state) => state
   )

   //set TabFilterRef

 export const setTabFilterRefSelector = createSelector(
  setTabFilterRefFeature,
   (state) => state.tabFilterRef
  )

  export const getTabFilterRefSelector = createSelector(
    selectTabFilterRefFeature,
    (state) => state
   )


      //set TabAssetChooserRef

 export const setTabAssetChooserRefSelector = createSelector(
  selectTabAssetChooserRefFeature,
   (state) => state.tabFilterRef
  )

  export const getTabAssetChooserRefSelector = createSelector(
    selectTabAssetChooserRefFeature,
    (state) => state
   )



   export const getUser = createSelector(
    selectUserFeature,
    (state) => state
   )
   export const getApplySelectedCustomer = createSelector(
    selectApplySelectedUidCustomer,
    (state) => state
   )


   export const getSelectedAssests = createSelector(
    selectSelectedAssets,
    (state) => state
   )


   export const getApplySelectedAssests = createSelector(
    selectApplySelectedAssets,
    (state) => state
   )

   export const getTabAssetDefaultAssetType = createSelector(
    selectTabAssetChooserRefFeature,
    (state) => state.defaultAssetType
   )


   export const getApplySelectedAssetTot = createSelector(
    selectApplySelectedAssets,
    (state) => state.totSelected
   )


   export const getSelectedPeriod = createSelector(
    selectSelectedPeriod,
    (state) => state
   )

   export const getApplySelectedPeriod = createSelector(
    selectApplySelectedPeriod,
    (state) => state
   )

   export const isApplySelectedAsset = createSelector(
    selectApplySelectedAssets,
    (state) => state.isApply
   )


   export const getCompleteState = createSelector(
    selectCompleteState,
    (state) => state
   )


   export const getCompleteStateIncludeChildrens = createSelector(
    selectCompleteState,
    (state) => state.includeChildrens
   )


   export const getFitlerRefSetting = createSelector(
    selectFilterRefSetting,
    (state) => state
   )


   export const getFitlerRefSettingIsSetting = createSelector(
    selectFilterRefSetting,
    (state) => state.isSetting
   )

   export const getFitlerRefSettingShowFilter = createSelector(
    selectFilterRefSetting,
    (state) => state.showFilter
   )

   export const getFitlerRefSettingShowDetailAssets = createSelector(
    selectFilterRefSetting,
    (state) => state.showDetailAssets
   )

   export const getFitlerRefSettingIncludeChildrens = createSelector(
    selectFilterRefSetting,
    (state) => state.showIncludeChildrens
   )


   export const getTotVehicles = createSelector(
    selectVehicleFeature,
    (state) => state.posts.length
   )

   export const getTotDrivers= createSelector(
    selectDriverFeature,
    (state) => state.posts.length
   )






