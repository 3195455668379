<section [id]="idComponent">
  <div class="d-flex">
    <div><strong>{{bulkBarTitle}}</strong></div>

    <div class="pull-right">
      <span class="pull-right hidden-xs">
        <!-- Edit -->
        <a (click)="edit()" class="btn btn-square-xs btn-default btn-xs"
         pTooltip=v tooltipPosition="left"
          *ngIf="showButtonEdit">
          <fa-icon [icon]="faPen"></fa-icon>

        </a>

        <a (click)="toggleVehicleDisable()" class="btn btn-default btn-square-xs btn-xs ml-1"
          pTooltip="{{tootipVehicleDisable}}" tooltipPosition="left"
          *ngIf="showButtonVehicleDisable">
          <!-- <span class="fa" [class.fa-ban]="!vehicleIsDisabled"
        [class.fa-check-circle]="vehicleIsDisabled" aria-hidden="true"></span> -->
          <fa-icon [icon]="faButtonVehicleDisable"></fa-icon>
        </a>



        <!-- Hide / Show data from reports -->
        <a (click)="toggleVehicleDataFromReports()" class="btn btn-default btn-square-xs btn-xs ml-1"
          pTooltip="{{tooltipHideShowData}}" tooltipPosition="left"
           *ngIf="showButtonHideShowData">
          <!-- <span class="fa" [class.fa-eye-slash]="vehicle?.lastIsDataHidden == false"
        [class.fa-eye]="vehicle?.lastIsDataHidden == true" aria-hidden="true"></span> -->
          <fa-icon [icon]="faButtonHideShowData"></fa-icon>

        </a>

        <!-- Change customer -->
        <a (click)="openChangeCustomer()" class="btn btn-default btn-square-xs btn-xs ml-1"
          pTooltip="{{tooltipChangeCustomer}}" tooltipPosition="left"
          *ngIf="showButtonChangeCustomer">
          <fa-icon [icon]="faArrowRightArrowLeft"></fa-icon>
        </a>

        <!-- Activating/Deactivating device -->
        <a (click)="toggleDeviceSilentMode()" class="btn btn-default btn-square-xs btn-xs ml-1"
           pTooltip="{{tooltipCanActivateDevice}}" tooltipPosition="left"
           *ngIf="showButtonCanActivatingDevice">
          <!-- <span class="fa" [class.fa-bell-slash]="!vehicle?.canActivatingDevice"
               [class.fa-bell]="vehicle?.canActivatingDevice" aria-hidden="true"></span> -->
          <fa-icon [icon]="faButtonCanActivatingDevice"></fa-icon>
        </a>


        <!-- Force Download DDD -->
        <a (click)="forceDownloadDDD()" class="btn btn-default btn-square-xs btn-xs ml-1"
           pTooltip="{{tooltipForceDownloadDDD}}" tooltipPosition="left"
           *ngIf="showButtonForceDownloadDDD">
          <fa-icon [icon]="faBoxArchive"></fa-icon>
        </a>


          <!-- Back Button -->
          <a (click)="backButton()" class="btn btn-default btn-square-xs btn-xs ml-1"
          pTooltip="{{tooltipBack}}" tooltipPosition="left"
          *ngIf="showButtonBack">
          <fa-icon [icon]="faBack"></fa-icon>
         </a>

        <!-- Save Button -->
          <a (click)="saveButton()" class="btn btn-default btn-square-xs btn-xs ml-1"
          pTooltip="{{tooltipSave}}" tooltipPosition="left"
           *ngIf="showButtonSave">
          <fa-icon [icon]="faSave"></fa-icon>
        </a>








      </span>
    </div>
  </div>
</section>
