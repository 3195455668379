import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { PBISession } from '../models/powerBIModels';
 
@Injectable({
    providedIn: 'root',
  })
export class SessionService {

    constructor() {}

    checkSession(key: string) {
        let currentDateMillis = new Date().getTime();
        let sessionObjectJSON = sessionStorage.getItem(key);
        if(sessionObjectJSON) {
            let sessionObject : PBISession = JSON.parse(sessionObjectJSON);
            let expiration = sessionObject.expires;
            if(expiration){
                if(currentDateMillis < expiration) {
                    //session is not expired
                    return sessionObject;
                } else {
                    //session expired 
                    //and remove the sessionStorage because it will be set again
                    sessionStorage.removeItem(key);
                    return null;
                }
            }
            else {
                return sessionObject;
            }
        }
        else {
            return null;
        }
    }

    get(key: string) {
        let sessionObject = this.checkSession(key);
        if(sessionObject) {
            return sessionObject.value;
        }   
        else {
            return null;
        }
    }

    remove(key: string) {
        sessionStorage.removeItem(key);
    }

    removeTokens(){
        let n = sessionStorage.length;
        let text = "powerbi_token";
        while(n--) {
            let key = sessionStorage.key(n);
            if(key && key.includes(text)) {
                sessionStorage.removeItem(key);
            }  
        }
    }

    set(key: string, value: string, expiration) {
        let sessionObject = {
            value: value,
            expires: expiration
        }
        sessionStorage.setItem(key,JSON.stringify(sessionObject));
    }

    decodeAndSet(key: string, value: string) {
        const tokenInfo = this.getDecodedAccessToken(value); // decode token
        const expireDateMillis = tokenInfo.exp * 1000; // get token expiration dateTime
        this.set(key,value,expireDateMillis);
    }

    getDecodedAccessToken(token: string): any {
        try {
          return jwt_decode(token);
        } catch(Error) {
          return null;
        }
    }
}