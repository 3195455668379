import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as PostActions from './actions';
import { Subscription, catchError, map, mergeMap, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { FilterRefService } from '../services/filter-ref.service';
import { AppStateInterface } from 'src/app/appState.interface';
import { Store, select } from '@ngrx/store';
import { getApplySelectedAssests, getTabAssetDefaultAssetType, selectedCustomerUidSelector } from './selectors';
import { SelectedAssets } from './interface/selectedAssets.interface';

@Injectable()
export class PostEffects {
  getCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostActions.getCustomers),
      mergeMap(() => {

        return this.postService.getCustomers$().pipe(
          map(
            (posts) => PostActions.getSuccesCustomers({ posts }),
            catchError((error) =>
              of(PostActions.getFailureCustomers({ error: error.message }))
            )
          )
        );
      })
    )
  );





  getVehiclesOfCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostActions.getVehiclesOfCustomers),
      mergeMap(() => {
        let uidCustomer;
        let subselectedCustomerUidSelector:Subscription;
        subselectedCustomerUidSelector = this.store.pipe(select(selectedCustomerUidSelector)).subscribe((res) =>
          {
            uidCustomer = res;
          }
          )
          subselectedCustomerUidSelector.unsubscribe();

        return this.postService.getVehiclesOfCustomers$(uidCustomer).pipe(
          // tap(val =>{
          //   let defaultAssetType;
          //   let getTabAssetDefaultAssetTypeSub = this.store.pipe(select(getTabAssetDefaultAssetType)).subscribe((res) => {
          //       defaultAssetType = res;
          //   });
          //   getTabAssetDefaultAssetTypeSub.unsubscribe();
          //   if (defaultAssetType == 'VEHIC')
          //   {
          //   let appStoreList:SelectedAssets ={
          //     assetType: 'VEHIC',
          //     selected:  JSON.parse(JSON.stringify(val)),
          //     totSelected:val.length,
          //     isApply:false

          //   }
          //   //#FZ setto selectedAssetes (locale) e controllo se non ho settato ApllySeletectAssets (globale)
          //   // appStoreList.selected = JSON.parse(JSON.stringify(val));
          //   this.store.dispatch(PostActions.setSelectedAssets(appStoreList ));
          //   let subgetApplySelectedAssests:Subscription
          //   subgetApplySelectedAssests = this.store.pipe(select(getApplySelectedAssests)).subscribe((res) =>
          //   {
          //     if (res.selected.length == 0)
          //     {
          //       this.store.dispatch(PostActions.setApplySelectedAssets(appStoreList ));
          //     }
          //   }
          //   )
          //   subgetApplySelectedAssests.unsubscribe();
          // }


          // } ),
          map(
            (posts) => PostActions.getSuccesVehiclesOfCustomers({ posts }),
            catchError((error) =>
              of(
                PostActions.getFailureVehiclesOfCustomers({
                  error: error.message
                })
              )
            )
          )
        );
      })
    )
  );


  getTrailersOfCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostActions.getTrailersOfCustomers),
      mergeMap(() => {
        let uidCustomer;
        let subselectedCustomerUidSelector:Subscription;
        subselectedCustomerUidSelector = this.store.pipe(select(selectedCustomerUidSelector)).subscribe((res) =>
          {
            uidCustomer = res;
          }
          )
          subselectedCustomerUidSelector.unsubscribe();
        return this.postService.getTrailersOfCustomers$(uidCustomer).pipe(
          map(
            (posts) => PostActions.getSuccesTrailersOfCustomers({ posts }),
            catchError((error) =>
              of(
                PostActions.getFailureTrailersOfCustomers({
                  error: error.message
                })
              )
            )
          )
        );
      })
    )
  );




  getFleetsOfCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostActions.getFleetsOfCustomers),
      mergeMap(() => {
        let uidCustomer;
        let subselectedCustomerUidSelector:Subscription;
        subselectedCustomerUidSelector = this.store.pipe(select(selectedCustomerUidSelector)).subscribe((res) =>
          {
            uidCustomer = res;
          }
          )
          subselectedCustomerUidSelector.unsubscribe();
        return this.postService.getFleetsOfCustomers$(uidCustomer).pipe(
          map(
            (posts) => PostActions.getSuccesFleetsOfCustomers({ posts }),
            catchError((error) =>
              of(
                PostActions.getFailureFleetsOfCustomers({
                  error: error.message
                })
              )
            )
          )
        );
      })
    )
  );

  getDriversOfCustomers$ = createEffect(() =>
  this.actions$.pipe(
    ofType(PostActions.getDriversOfCustomers),
    mergeMap(() => {
      let uidCustomer;
      this.store.pipe(select(selectedCustomerUidSelector)).subscribe((res) =>
       {
         uidCustomer = res;
       }
       )

      return this.postService.getDriversOfCustomers$(uidCustomer).pipe(
        // tap(val =>{

        //   let defaultAssetType;
        //   let getTabAssetDefaultAssetTypeSub = this.store.pipe(select(getTabAssetDefaultAssetType)).subscribe((res) => {
        //       defaultAssetType = res;
        //   });
        //   getTabAssetDefaultAssetTypeSub.unsubscribe();
        //   if (defaultAssetType == 'DRIVR')
        //   {
        //     let appStoreList:SelectedAssets ={
        //       assetType: 'DRIVR',
        //       selected:  JSON.parse(JSON.stringify(val)),
        //       totSelected:val.length,
        //       isApply:false

        //     }
        //   //#FZ setto selectedAssetes (locale) e controllo se non ho settato ApllySeletectAssets (globale)
        //   //appStoreList.selected = JSON.parse(JSON.stringify(val));
        //   this.store.dispatch(PostActions.setSelectedAssets(appStoreList ));
        //   let subgetApplySelectedAssests:Subscription
        //   subgetApplySelectedAssests = this.store.pipe(select(getApplySelectedAssests)).subscribe((res) =>
        //   {
        //     if (res.selected.length == 0)
        //     {
        //       this.store.dispatch(PostActions.setApplySelectedAssets(appStoreList ));
        //     }
        //   }
        //   )
        //   subgetApplySelectedAssests.unsubscribe();

        // }

        // } ),
        map(
          (posts) => PostActions.getSuccesDriversOfCustomers({ posts }),
          catchError((error) =>
            of(
              PostActions.getFailureDriversOfCustomers({
                error: error.message
              })
            )
          )
        )
      );
    })
  )
);

getTeamsOfCustomers$ = createEffect(() =>
this.actions$.pipe(
  ofType(PostActions.getTeamsOfCustomers),
  mergeMap(() => {
    let uidCustomer;
    this.store.pipe(select(selectedCustomerUidSelector)).subscribe((res) =>
     {
       uidCustomer = res;
     }
     )
    return this.postService.getTeamsOfCustomers$(uidCustomer).pipe(
      map(
        (posts) => PostActions.getSuccesTeamsOfCustomers({ posts }),
        catchError((error) =>
          of(
            PostActions.getFailureTeamsOfCustomers({
              error: error.message
            })
          )
        )
      )
    );
  })
)
);






  constructor(
    private actions$: Actions,
    private postService: FilterRefService,
    private store:Store<AppStateInterface>
  ) {


  }
}
