
<div class="flex flex-col  ">
  <div class="flex justify-center p-2">
    <dx-select-box #selectTimeStamp [value]="currentTimespan" (onSelectionChanged)="selectBoxChanged($event)" [searchEnabled]="true"
      [dataSource]="timespanSelectBoxItems" displayExpr="value" valueExpr="id" placeholder="selectTimePeriod(trans)" *ngIf="timespanSelectBoxItems.length > 1">
    </dx-select-box>
    <div *ngIf="timespanSelectBoxItems.length ==1" class="font-bold">
       {{timespanSelectBoxItems[0].value}}
    </div>
  </div>
  <p-divider align="center">
    <div class="inline-flex align-items-center ">
      <i class="pi pi-angle-double-down  text-sm"></i>

    </div>
  </p-divider>




    <div class="flex flex-col justify-center  ">


      <div class="flex  justify-center p-2 ">
        <!-- label and value -->
        <div class="flex justify-center pr-2">
          <div *ngIf="currentTimespan != 'last7days'" class="aft-filter__time-span-chooser__label">
            <strong *ngIf="currentTimespan == 'timeperiod'" translate="global.from"></strong>
            <strong *ngIf="currentTimespan == 'dateTimePeriod'" translate="global.from"></strong>
            <strong *ngIf="currentTimespan == 'daily'" translate="global.day"></strong>
            <strong *ngIf="currentTimespan == 'month'" translate="global.month"></strong>
            <strong *ngIf="currentTimespan == 'week'" translate="global.week"></strong>
          </div>
        </div>
        <!-- from datepicker -->
        <div *ngIf="currentTimespan != 'last7days' && currentTimespan != 'dateTimePeriod'" class="flex justify-center ">
          <dx-date-box (onValueChanged)="fromValueChanged($event)" [max]="nowDate" [displayFormat]="displayFormat"
            [calendarOptions]="calendarOptions" [value]="fromDtPicker" type="date" class="w-40">
          </dx-date-box>
        </div>

        <div *ngIf="currentTimespan == 'dateTimePeriod'" class="flex justify-center ">
          <dx-date-box (onValueChanged)="fromValueChanged($event)" [max]="nowDate" [displayFormat]="displayDateTimeFormat"
            [calendarOptions]="calendarOptions" [value]="fromDtPicker" type="datetime" class="w-40">
          </dx-date-box>
        </div>

      </div>
      <div class="flex justify-center" *ngIf="currentTimespan == 'dateTimePeriod'">

        <!-- label and value -->
        <div class="pr-2">
          <div class="aft-filter__time-span-chooser__label">
            <strong translate="global.to"></strong>
          </div>
        </div>

        <!-- to datepicker -->
        <div>
          <dx-date-box (onValueChanged)="toValueChanged($event)" [max]="nowDate" [min]="fromDtPicker"
            [displayFormat]="displayDateTimeFormat" [calendarOptions]="calendarOptions" [value]="toDtPicker" type="datetime"
            class="w-40 pl-2">
          </dx-date-box>


        </div>

      </div>
      <div class="flex justify-center" *ngIf="currentTimespan == 'timeperiod'">

        <!-- label and value -->
        <div class="pr-2">
          <div class="aft-filter__time-span-chooser__label">
            <strong translate="global.to"></strong>
          </div>
        </div>

        <!-- to datepicker -->
        <div>
          <dx-date-box (onValueChanged)="toValueChanged($event)" [max]="nowDate" [min]="fromDtPicker"
            [displayFormat]="displayFormat" [calendarOptions]="calendarOptions" [value]="toDtPicker" type="date"
            class="w-40 pl-2">
          </dx-date-box>


        </div>

      </div>
      <!-- <div *ngIf="currentTimespan == 'daily'">
        <div class="flex justify-center" >

          <div class="pr-2">
            <div class="aft-filter__time-span-chooser__label">
              <strong translate="global.fromTime"></strong>
            </div>
          </div>
          <div>
            <dx-date-box
            id="fromTime"
            [value]="fromTime"
            type="time"
            (onValueChanged)="fromTimeValueChanged($event)"
            [inputAttr]="{ 'aria-label': 'Time' }"
            displayFormat="HH:mm"
            class="w-40"
          >   </dx-date-box>

          </div>
        </div>

      <div class="flex justify-center" >
        <div class="pr-2">
          <div class="aft-filter__time-span-chooser__label">
            <strong translate="global.toTime"></strong>
          </div>
        </div>
        <div>
          <dx-date-box
          id="toTime"
          [value]="toTime"
          type="time"
          (onValueChanged)="toTimeValueChanged($event)"
          [inputAttr]="{ 'aria-label': 'Time' }"
          class="w-40"
          displayFormat="HH:mm"
        >   </dx-date-box>

        </div>


      </div>




    </div> -->


  <!-- <div class="flex justify-center">
    <div *ngIf="currentTimespan != 'timeperiod' && currentTimespan != 'daily'">
      <div class="aft-filter__time-span-chooser__label">
        <strong translate="global.from"></strong> {{ summaryFromDate }}
        &nbsp;<strong translate="global.to"></strong> {{ summaryToDate }}
      </div>
    </div>
  </div> -->
</div>
</div>
