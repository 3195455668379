import { UtilsService } from 'src/app/core/services/utils.service';
import { DataService } from 'src/app/core/services/data.service';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { KeycloakService } from 'keycloak-angular';
import { mapRealm } from 'src/environments/environment';

@Injectable()
export class KeycloakAuthService {
  redirectUri: String;
  idpHint?: String;

  constructor(
    private dataService: DataService,
    private utilsService: UtilsService,
    private keycloakService: KeycloakService
  ) {
    const realmConfig = mapRealm.get(window.location.hostname);

    this.redirectUri = realmConfig?.redirectUri ?? window.location.origin;
    this.idpHint = realmConfig?.idpHint;
  }

  logout() {
    localStorage.setItem('cookieClicked', 'false');
    let url = this.keycloakService
      .getKeycloakInstance()
      .createLogoutUrl({ redirectUri: this.redirectUri.toString() });
    // if (!this.keycloakService.getKeycloakInstance().idToken) {
    //   const urlTemp = new URL(url);
    //   const params = urlTemp.searchParams;
    //   params.delete('post_logout_redirect_uri');
    //   url = urlTemp.origin + '?' + params.toString();
    // }

    window.location.href = url;
  }

  isAuthenticated(): void {
    // console.log('KeycloakAuthService.isAuthenticated');
    let self = this;
    this.keycloakService
      .isLoggedIn()
      .then((data) => {
        //console.log('this.keycloakService.isLoggedIn() ' + data);
      })
      .catch((error) => {
        console.log('error: ' + error);

        const keycloakLoginOptions: Record<string, any> = {};
        if (self.idpHint != null) {
          keycloakLoginOptions.idpHint = self.idpHint.toString();
        }

        self.keycloakService.login(keycloakLoginOptions);
      });
  }

  isFeatureGranted(functionalityName: any, level: any) {
    // console.log(
    //   'isFeatureGranted - functionalityName: ' +
    //     functionalityName +
    //     ' level: ' +
    //     level
    // );
    level = level.toLowerCase();
    var feature;
    let user = this.dataService.getCurrentUser();
    this.isAuthenticated();
    if (
      !_.isEmpty(user.user?.group) &&
      !_.isEmpty(user.user?.group?.features)
    ) {
      for (var i = 0; i < user.user.group.features.length; ++i) {
        feature = user.user.group.features[i];
        for (var k = 0; k < feature.functionalities.length; ++k) {
          if (feature.functionalities[k].name === functionalityName) {
            if (
              level === 'write' &&
              feature.functionalities[k].settings.manage
            ) {
              return true;
            }
            if (
              level === 'read' &&
              feature.functionalities[k].settings.enabled
            ) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  // Check if user has write grant on provided menu label name
  isMenuLabelWriteGranted(menuLabelName) {
    let user = this.dataService.getCurrentUser();
    var menuItem = this.utilsService.getMenuItem(user.menu, menuLabelName);
    if (menuItem !== undefined) {
      return menuItem.w;
    } else {
      return false;
    }
  }

  // Check if user has read grant on provided menu label name
  isMenuLabelReadGranted(menuLabelName) {
    let user = this.dataService.getCurrentUser();
    var menuItem = this.utilsService.getMenuItem(user.menu, menuLabelName);
    if (menuItem !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  //keycloak functions

  redirectToProfile() {
    this.keycloakService.getKeycloakInstance().accountManagement();
  }

  getRoles(): string[] {
    return this.keycloakService.getUserRoles();
  }

  getLoggedUser() {
    try {
      let userDetails =
        this.keycloakService.getKeycloakInstance().idTokenParsed;
      console.log('UserDetails', userDetails);
      return userDetails;
    } catch (e) {
      console.log('getLoggedUser Exception', e);
      return undefined;
    }
  }
}
