import { Component, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { EventEmitter } from '@angular/core';

@Component({
    selector: 'grid-toolbar',
    templateUrl: './grid-toolbar.component.html',
    styleUrls: ['./grid-toolbar.component.scss']
})
export class GridToolbarComponent implements OnInit {
    @Output() onPdfClicked = new EventEmitter<any>();
    @Output() onExportClicked = new EventEmitter<any>();
    @Input() options: any;

    defaultConfig = {
        showSearchBox: true,
        showDrivers: false,
        showVehicles: false,
        showTitle: false,
        showDownload: false,
        showUpload: false,
        showClearFileList: false,
        showPDF: false,
        showExport: false,
        showRead: false,
        showSwitch: false,
        switch: {
            onText: 'On',
            offText: 'Off',
            width: 100
        },
        title: ''
    };

    constructor() {
    }

    ngOnInit(): void {
        this.options = _.extend(this.defaultConfig, this.options);
    }

    btnExportClick() {
        this.onExportClicked.emit()
    }

    btnPdfClick() {
        this.onPdfClicked.emit()
    }

}
