import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, tap } from 'rxjs';
import { DataService } from '../services/data.service';
import { CurrentUserInfo } from '../models/currentUserInfo';

@Injectable({
  providedIn: 'root'
})
export class PreferenceInterceptor implements HttpInterceptor {
  constructor(private dataService: DataService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.match(/api\//) && !req.url.match('/api/users/current')) {
      if (!window.location.hash.endsWith('preferences')) {
        const userInfo: CurrentUserInfo = this.dataService.getCurrentUserInfo();
        if (!userInfo.user?.preferences) {
          window.location.href = window.location.origin + '#/app/preferences';
          return EMPTY;
        }
      }
    }

    return next.handle(req);
  }
}
