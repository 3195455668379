import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { faCogs } from '@fortawesome/free-solid-svg-icons';
import { ICON_STATE_MAPPER, ROUTER_STATE_MAPPER } from 'src/app/core/config/config.constants';
import { MenuItem } from 'src/app/core/models/menuItem';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { SideNavItem } from './sidenav-item';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { KeycloakAuthService } from 'src/app/core/services/keycloak.auth.service';

@Component({
  selector: 'app-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss']
})
export class SidenavItemComponent implements OnInit, OnDestroy {
  public faGears = faCogs;

  @Input() menuItem: MenuItem;
  @Input() stylesheetString: string;
  routingSubscription: Subscription;

  @ViewChild("clickMenuTrigger") clickMenuTrigger: MatMenuTrigger;

  dataSource: any;

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private keycloakAuthService: KeycloakAuthService
  ) {
    this.routingSubscription = router.events.subscribe((val:any) => {
        // see also
        if(val?.url?.includes('home') || val?.url?.includes('alertreport') || val?.url?.includes('preferences')){
          this.clearSelection(true);
        }
    });
  }

  ngOnDestroy(): void {
    this.routingSubscription.unsubscribe();
  }
  subItemClick(subItem: SideNavItem) {
    //console.log(subItem.label);
    let url = ROUTER_STATE_MAPPER[subItem.label];
    this.navigateToPage(url);
    this.keycloakAuthService.isAuthenticated();
  }

  onSubMenuClose() {
    this.clearSelection(false);
  }

  clearSelection(navigateAction: boolean) {
    let navItemGroup = document.querySelectorAll(".nav-item");
    navItemGroup.forEach((navItem) => {
      if (navItem.classList.contains("item-active")) {
        navItem.classList.remove("item-active");
      }
      if (navigateAction && navItem.classList.contains("item-selected")) {
        navItem.classList.remove("item-selected");
      }
    });
  }

  navigateToPage(url: string) {
    this.clearSelection(true);
    (document.querySelector(".nav-item.item-" + this.menuItem.label) as HTMLElement).classList.add("item-selected");
    this.router.navigate([url]);
  }

  itemClicked() {
    this.clearSelection(false);
    if (this.menuItem.child.length <= 0) {
      let url = ROUTER_STATE_MAPPER[this.menuItem.label];
      this.navigateToPage(url);
      this.clickMenuTrigger.closeMenu();
    } else {
      let menuItemDom = (document.querySelector(".nav-item.item-" + this.menuItem.label) as HTMLElement);
      if (menuItemDom && !menuItemDom.classList.contains("item-selected"))
        menuItemDom.classList.add("item-active");

    }
  }

  ngOnInit(): void {
    this.initNavItemStyle();
    this.dataSource = [{ text: this.translateService.instant('home.openInNewTab') }];
  }

  initNavItemStyle() {
    let found = false;
    if (this.menuItem.child.length > 0) {
      this.menuItem.child.forEach((elementChild) => {
        if (ROUTER_STATE_MAPPER[elementChild.label] == this.router.url) found = true;
      })
    } else {
      if (ROUTER_STATE_MAPPER[this.menuItem.label] == this.router.url) found = true;
    }
    if (found) {
      setTimeout(() => {
        let menuItemDom = (document.querySelector(".nav-item.item-" + this.menuItem.label) as HTMLElement);
        menuItemDom?.classList?.add("item-selected");
      }, 50);

    };
  }

  onRightClick($event: MouseEvent) {
    $event.preventDefault();
    if (this.menuItem?.child?.length <= 0) {
      this.clickMenuTrigger.openMenu();
    }
  }

  getLabel(label?: string): string {
    if (!label) {
      label = this.menuItem.label;
    }
    if (this.menuItem) {
      return "global.menu." + label;
    }
    return '';
  }

  getIcon() {
    return ICON_STATE_MAPPER[this.menuItem.label];
  }

  getChilds() {
    return _.orderBy(this.menuItem?.child, ['order'], ['asc']);
  }

  itemClick(subItem: any): void {
    let url = ROUTER_STATE_MAPPER[subItem.label];
    window.open('/#/' + url);
  }

  // #6264 FZ
  itemPrincClick(menuItem:any){
    console.log(menuItem)
    let url = ROUTER_STATE_MAPPER[menuItem.label];
    console.log(url)
    window.open('/#' + url);

  }
  // #6264 FZ
  childCount(menuItem:any){
    return menuItem.child.length;
  }
}
