import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ALERTS_WARNING } from 'src/app/core/config/config.constants';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-modal-msg',
  templateUrl: './modal-msg.component.html',
  styleUrls: ['./modal-msg.component.scss']
})
export class ModalMsgComponent implements OnInit {
  mgsText: string = '';
  mgsHeading: string = '';
  visible: boolean = false;
  @Input() buttonInfo: boolean;
  borderColorExpression: boolean = true;
  @Output() okClicked: EventEmitter<any> = new EventEmitter();

  borderColorInfo: '#099099';
  borderColorWarning: '#f7bd00';

  constructor() { }

  ngOnInit(): void {
  }
  
  onOkClick() {
    this.okClicked.emit();
    this.visible = false;
  }
  
  showDialog() {
    if (!this.borderColorExpression) {
      console.log(this.buttonInfo);
      document.documentElement.style.setProperty('--modal-color-info', '#f7bd00');
      document.documentElement.style.setProperty('--pad-left', '30px')
      document.documentElement.style.setProperty('--pad-left', '16px')
    }
    this.visible = true;
  }

}
