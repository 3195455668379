import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faBinoculars, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ICON_STATE_MAPPER } from 'src/app/core/config/config.constants';
import { FilterOptions } from 'src/app/core/models/filterOptions';
import { AuthService } from 'src/app/core/services/auth.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { AssetsSearchComponent } from '../assets-search/assets-search.component';
import { FilterService } from '../filter/filter.service';
import { HelpHeaderPopupComponent } from './help-header-popup/help-header-popup.component';
import { Route, Router } from '@angular/router';




@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {

  constructor(
              // private filterService: FilterService,
              public translateService: TranslateService,
              private utilsService: UtilsService,
              private authService: AuthService,
              private dialogService: MatDialog,
              private router: Router) { }

  @Input() headTitle: string;
  @Input() help: any = null;

  @Output() onButtonHelpEvent = new EventEmitter()

  faBinoculars: any;
  faHelp: any;
  searchAssetEnable: boolean = true;

  // applyFilterEvent: Subscription;
  // assetsInitEvent: Subscription;

  isCustomerVisible: boolean;
  customerString: string;

  isAssetsVisible: boolean;
  assetsObject: Partial<{
    icon: any,
    type: string,
    length: number
  }> = {};

  isTimespanVisible: boolean;
  timespanObject: Partial<{
    timespan: string,
    from: any,
    to: any
  }> = {};

  ngOnInit(): void {
    this.searchAssetEnable = this.authService.isFeatureGranted('customers', 'read');
    this.faBinoculars = faBinoculars;
    this.faHelp = faQuestion;
    // let filterOptions = this.filterService.getCurrentFilterOptions();
    // this.setFilterRecapSection(filterOptions);
    // this.applyFilterEvent = this.filterService.applyFilterEvent.subscribe((data) => {
    //   this.setFilterRecapSection(filterOptions);
    // })
    // this.assetsInitEvent = this.filterService.assetsInitEvent.subscribe((data) => {
    //   this.setFilterRecapSection(filterOptions);
    // })
  }

  openHelpPopup(){
    console.log("this.router.url",this.router.url)
    if (this.router.url == '/app/manage/trailers' || this.router.url == '/app/tacho/reportOnDemand' || this.router.url == '/app/historyJourney')
    {
      this.onButtonHelpEvent.emit(this.router.url)

    }

    else

    this.dialogService.open(HelpHeaderPopupComponent, {
      width: '600px',
      data: this.help
    });
  }

  openSearchAsset() {
    this.dialogService.open(AssetsSearchComponent, {
      width: "650px"
    })
  }


  // setFilterRecapSection(filterOptions: FilterOptions | null) {
  //   setTimeout(() => {
  //     if (filterOptions != null) {
  //       this.isCustomerVisible = filterOptions.customer;
  //       if (this.isCustomerVisible) this.customerString = this.filterService.getSelectedCustomer().name;
  //       this.isAssetsVisible = filterOptions.assets != null;

  //       if (this.isAssetsVisible) {
  //         this.assetsObject.length = this.filterService.getSelectedAssets()?.assets.selected.length;
  //         this.assetsObject.type = this.filterService.getSelectedAssets()?.assets.type;
  //         switch (this.assetsObject.type) {
  //           case 'vehicle': this.assetsObject.icon = ICON_STATE_MAPPER['tacho']; break;
  //           case 'fleets': this.assetsObject.icon = ICON_STATE_MAPPER['tacho']; break;
  //           case 'driver': this.assetsObject.icon = "fa fa-user"; break;
  //           case 'teams': this.assetsObject.icon = "fa fa-group"; break;
  //         }
  //       }
  //       this.isTimespanVisible = filterOptions.timespan != null;
  //       this.translateService.get('global.dateFormat').subscribe(dateFormat=>{
  //         if (this.isTimespanVisible) {
  //           this.timespanObject.timespan = this.filterService.getTimespanSelection();
  //           this.timespanObject.to = this.utilsService.formatDateFromEpoch(this.filterService.getToDate(), dateFormat, null);
  //           this.timespanObject.from = this.utilsService.formatDateFromEpoch(this.filterService.getFromDate(), dateFormat, null);
  //         }
  //       });
  //     }
  //   }, 100);

  // }

  ngOnDestroy(): void {
    // this.assetsInitEvent?.unsubscribe();
    // this.applyFilterEvent?.unsubscribe();
}

}
