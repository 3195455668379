import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { UtilsService } from 'src/app/core/services/utils.service';
import { faHammer, faBed, faSquareCheck, faScrewdriverWrench, faCircleRadiation } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'activities-tile',
  templateUrl: './activities-tile.component.html',
  styleUrls: ['./activities-tile.component.scss']
})
export class ActivitiesTileComponent implements OnInit {
  @Input() data: any;
  @Input() options: any;
  faHammer: any;
  faBed: any;
  faSquareCheck: any;
  faScrewdriverWrench: any;
  faCircleRadiation: any;
  isVisibleInfr = false;
  isVisiblePlaces = false;
  isVisibleScope = false;

  defaultConfig = {
    isDaily: false,
    monthly: false
  };
  scrollViewOptions = {
    width: '100%',
    height: '100%',
  };

  visiblePopup = false;//Flag to show popup
  targetElementInfr: string;
  targetElementPlace: string;
  targetElementScope: string;
  

  constructor(private utilsService: UtilsService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.faHammer = faHammer;
    this.faBed = faBed;
    this.faSquareCheck = faSquareCheck;
    this.faScrewdriverWrench = faScrewdriverWrench;
    this.faCircleRadiation = faCircleRadiation;
    this.options = _.extend(this.defaultConfig, this.options);
  }

  getDayFromTimestamp(date){
    return this.utilsService.getDayFromTimestamp(date);
  }

  infrigmentsDetails(event, id: any): void {
    this.targetElementInfr = '#infr'+id;
    this.isVisibleInfr = !this.isVisibleInfr;
  }

  placesDetails(event, id: any) {
    this.targetElementPlace = '#place'+id;
    this.isVisiblePlaces = !this.isVisiblePlaces
  }

  currentScopeDetails(event, id: any) {
    this.targetElementScope = '#scope'+id;
    this.isVisibleScope = !this.isVisibleScope;
  }
}
