import { ApplicationRef, Component, OnInit, ViewChild } from '@angular/core';
import { UtilsService } from 'src/app/core/services/utils.service';

import { TranslateService } from '@ngx-translate/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { DataService } from 'src/app/core/services/data.service';

import { FilterOptions } from 'src/app/core/models/filterOptions';
import { Store, select } from '@ngrx/store';

import * as PostActions from '../../../core/store/actions';
import { browserRefresh } from 'src/app/app.component';

import {
  Observable,
  Subscription,
  combineLatest,
  from,
  sequenceEqual,
  tap
} from 'rxjs';
import { AppStateInterface } from 'src/app/appState.interface';
import {
  errorCustomersSelector,
  isLoadingCustomersSelector,
  getCustomersSelector,
  selectedCustomerNameSelector,
  selectedCustomerUidSelector,
  getVehiclesOfCustomersSelector,
  getTabFilterRefSelector,
  selectedCustomerSelector,
  getSelectedAssests,
  getApplySelectedAssests,
  getApplySelectedCustomer,
  getTabAssetChooserRefSelector,
  getDriversOfCustomersSelector,
  getTabAssetDefaultAssetType,
  getApplySelectedAssetTot,
  isLoadingVehiclesOfCustomersSelector,
  isLoadingDriversOfCustomersSelector,
  getSelectedPeriod,
  getApplySelectedPeriod,
  getFitlerRefSettingShowFilter,
  getFitlerRefSettingShowDetailAssets,
  getFitlerRefSettingIncludeChildrens,
  getCompleteStateIncludeChildrens,
  getTotVehicles,
  getTotDrivers,
  getCompleteState,
  getFitlerRefSetting
} from 'src/app/core/store/selectors';
import { FilterRefService } from 'src/app/core/services/filter-ref.service';
import { SelectedCustomerCacheInterface } from 'src/app/core/store/interface/selectedCustomerCache.interface';
import {
  ListTabRefInterface,
  TabFilterRefInterface
} from 'src/app/core/store/interface/tabFilterRef.interface';
import { SelectedAssets } from 'src/app/core/store/interface/selectedAssets.interface';
import { NavigationStart, Router } from '@angular/router';
import * as moment from 'moment';
import { SelectedPeriodFitler } from 'src/app/core/store/interface/selectedPeriod.interface';
import { CompleteApplyState } from 'src/app/core/store/interface/completeApplyState.interface';
import { GeListStateInterface } from 'src/app/core/store/interface/getListState.interface';
import { getCompleteApplyState } from '../../../core/store/actions';
import { locale } from 'devextreme/localization';

export class Longtab {
  id: number;

  text: string;
}

export class Tab {
  id: number;

  text: string;

  icon: string;

  content: string;
}

const Longtabs: TabFilterRefInterface[] = [];

@Component({
  selector: 'app-filter-ref',
  templateUrl: './filter-ref.component.html',
  styleUrls: ['./filter-ref.component.scss']
})
export class FilterRefComponent implements OnInit {



  stylesheetString = 'aft';
  panelOpen: boolean = false;
  longtabs = Longtabs;
  choosedTab: any;
  scrollByContent = true;
  scrollByThumb = true;
  scrollbarMode: string;
  customers: any;
  defaultAssetype: string;

  totAssetsApply: number;
  posts$: any;
  selectedCusdtomerFromTree: SelectedCustomerCacheInterface;
  applyFilterDone: boolean = false;

  subgetTabFilterRefSelector: Subscription;
  subgetSelectedAssests: Subscription;
  subgetSelectePeriod: Subscription;
  getTabAssetsChooser: Subscription;
  checkLoadinDataSub:Subscription;
  subgetSelectedCustomer:Subscription;

  selectedCustomerName$: Observable<string | null>;
  selectedUidCustomer$: Observable<string | null>;
  totAssetsApply$: Observable<number>;
  isLoading$: Observable<boolean>;
  showFilter$: Observable<boolean>;
  showFilterAssets$ :Observable<boolean>;
  includeChildrens$:Observable<boolean>;

  isLoadingCustomers$: Observable<boolean>;
  isLoadingDrivers$: Observable<boolean>;
  totVechiles$:Observable<number>;
  totDrivers$:Observable<number>;

  titDefaultAssetype: string;

  appStoreAssetsList: SelectedAssets = {
    assetType: '',
    selected: [],
    totSelected: 0,
    isApply: false
  };

  appStorePeriod: SelectedPeriodFitler = {
    fromDtPicker: moment().valueOf(),
    toDtPicker: moment().valueOf(),
    timespanSel: 'week', //timeperiod month daily week last7days
    isApply: false
  };


  browserRefresh: any;
  isTimespanVisible: boolean = false;

  fromDtPicker$: number | null;
  toDtPicker$: number | null;
  timeSpanSelected:string;
  dateFormat: string;
  dateTimeFormat:string;
  selectedStoreCustomer: any;
  selecteStorePeriod: any;
  userUtc: string;
  tabAssetsChooser:any;
  isCustomerChange:boolean=false;
  showHeader = false;
  includeChildrenValue: any = false;
  callNewPage:boolean = false;
  userInfo = this.dataService.getCurrentUserInfo();
  assetTitleDesc:string ='';
  oldSelectionMode:ListTabRefInterface | null;

  @ViewChild('opFilterRef') public op: OverlayPanel = null as any;

  constructor(
    private utilsService: UtilsService,
    public translateService: TranslateService,
    private dataService: DataService,
    private filterRefService: FilterRefService,
    private store: Store<AppStateInterface>,
    private router: Router
  ) {
    // //#FZ Filter Ref set tab
    // let tabUsed: ListTabRefInterface = {
    //   isLoading: true,
    //   tabFilterRef: [],
    //   error: null
    // };
    // this.store.dispatch(PostActions.setTabFilterRef(tabUsed));
    this.translateService.setDefaultLang(this.dataService.getLanguage());


    this.isLoading$ = this.store.pipe(select(isLoadingCustomersSelector));

    this.showFilter$ = this.store.pipe(select(getFitlerRefSettingShowFilter))


    this.store.pipe(select(getFitlerRefSetting)).subscribe((res) => {
      // this.selectedStoreCustomer = JSON.parse(JSON.stringify(res));

        if (!res.showFilter )
        if (this.op)
          this.op.hide();
    });



    this.showFilterAssets$ = this.store.pipe(select(getFitlerRefSettingShowDetailAssets))

    this.includeChildrens$ = this.store.pipe(select(getFitlerRefSettingIncludeChildrens))
    this.totVechiles$ = this.store.pipe(select(getTotVehicles))
    this.totDrivers$ = this.store.pipe(select(getTotDrivers))



    // this.defaultAssetType$ = this.store.pipe(
    //   select(getTabAssetDefaultAssetType)
    // )

    this.totAssetsApply$ = this.store.pipe(select(getApplySelectedAssetTot));

    this.isLoadingCustomers$ = this.store.pipe(
      select(isLoadingVehiclesOfCustomersSelector)
    );
    this.isLoadingDrivers$ = this.store.pipe(
      select(isLoadingDriversOfCustomersSelector)
    );

    this.posts$ = this.store.pipe(select(getCustomersSelector));

    this.store.pipe(select(getApplySelectedCustomer)).subscribe((res) => {
      this.selectedStoreCustomer = JSON.parse(JSON.stringify(res));
        //console.log('getApplySelectedCustomer',res);
    });

    this.selectedCustomerName$ = this.store.pipe(
      select(selectedCustomerNameSelector)
    );
    this.selectedUidCustomer$ = this.store.pipe(
      select(selectedCustomerUidSelector)
    );

    // //FZ ricevo elenco delle tab da visualizzare e inizializzo
    this.subgetTabFilterRefSelector = this.store
      .pipe(select(getTabFilterRefSelector))
      .subscribe((res) => {
        // console.log('getTabFilterRefSelector',res);
        this.longtabs = JSON.parse(JSON.stringify(res.tabFilterRef));
        //console.log(this.longtabs)
        let defTimeSpan;
        let defFromDate;
        let defToDate;
        let isJustApplyPeriod;
        let isisJustApplyPeriodRow;
        let getOldSelectedPeriod: Subscription = this.store
          .pipe(select(getApplySelectedPeriod))
          .subscribe((resPeriod) => {
            this.selecteStorePeriod = JSON.parse(JSON.stringify(resPeriod));
            isJustApplyPeriod = resPeriod.isApply;
            isisJustApplyPeriodRow = resPeriod;
            // verificare da qui il refresh
          });
        getOldSelectedPeriod.unsubscribe();

        if (this.longtabs.length == 3) {
          if (this.longtabs[2].intialParameter?.length) {
            defTimeSpan =
              this.longtabs[2].intialParameter[0].timespan[0].timespan;
              if (this.longtabs[2].intialParameter[0].timespan[0].fromDateDef && this.longtabs[2].intialParameter[0].timespan[0].toDateDef)
              {
                defFromDate = this.longtabs[2].intialParameter[0].timespan[0].fromDateDef
                defToDate = this.longtabs[2].intialParameter[0].timespan[0].toDateDef
                isJustApplyPeriod = false;
            }


          } else {
            defTimeSpan = this.appStorePeriod.timespanSel;
            defFromDate = this.appStorePeriod.fromDtPicker;
            defToDate = this.appStorePeriod.toDtPicker;

          }
          this.isTimespanVisible = true;
          //console.log(defTimeSpan)

          let calcDate = this.calcToDate(
            defTimeSpan,
            defFromDate,
            defToDate
          );
           // console.log(calcDate)
          let newStorePeriod: SelectedPeriodFitler = {
            fromDtPicker: calcDate.fromDate,
            toDtPicker: calcDate.toDate,
            timespanSel: defTimeSpan, //timeperiod month daily week last7days
            isApply: false
          };

          if (!isJustApplyPeriod) {
            this.store.dispatch(
              PostActions.setApplySelectedPediod(newStorePeriod)
            );
            this.store.dispatch(
              PostActions.setCompleteApplyStateSelectedPeriod({applySelectedPeriod : newStorePeriod})
            );

            this.store.dispatch(PostActions.setSelectedPediod(newStorePeriod));
           // console.log('not isJustApplyPeriod',newStorePeriod)
          } else {
            // fz se cambia periodo risetto
            if (defTimeSpan != isisJustApplyPeriodRow.timespanSel )
            {
              this.store.dispatch(
                PostActions.setApplySelectedPediod(newStorePeriod)
              );
              this.store.dispatch(
                PostActions.setSelectedPediod(newStorePeriod)
              );
              this.store.dispatch(
                PostActions.setCompleteApplyStateSelectedPeriod({applySelectedPeriod : newStorePeriod})
              );

             // console.log('is isJustApplyPeriod',newStorePeriod)


            }
            else
            {
             // console.log(isisJustApplyPeriodRow)
              this.store.dispatch(
                PostActions.setSelectedPediod(isisJustApplyPeriodRow)
              );
              //console.log('isisJustApplyPeriodRow',isisJustApplyPeriodRow)
            }

          }
        } else {
          // FZ non metto gli assets ma rendo cmq visibile i filtri per data (id == 2 )
          if (this.longtabs.length == 2 && this.longtabs[1].id == 2)
          this.isTimespanVisible = true;
          else
          this.isTimespanVisible = false;
        }

        for (let elem of this.longtabs) {
          elem.text = this.translateService.instant(elem.text);
        }
        // fz nascondo il pannello ogni volta che cambio tab setting

        try {
          this.op.hide()
        } catch (error) {
          null
        }

      });
      // subscribe a cambio selected Assets
    this.subgetSelectedAssests = this.store
      .pipe(select(getSelectedAssests))
      .subscribe((res) => {
        this.totAssetsApply = res.totSelected;
        this.titDefaultAssetype = res.assetType;
        this.assetTitleDesc ='';
        if (res.totSelected == 1 && res.assetType == 'DRIVR')
        {
            this.assetTitleDesc = res.selected[0]['fullName']
        }
        else
        if (res.totSelected == 1 && res.assetType == 'VEHIC')
        {
          this.assetTitleDesc = res.selected[0]['plate']
          }



      });

  // // subscribe a cambio Customer
  //   this.subgetSelectedCustomer = this.store
  //   .pipe(select(selectedCustomerSelector))
  //   .subscribe((resSelectedCustomer) => {
  //     console.log(resSelectedCustomer)
  //     let appCustomerSelected =  JSON.parse(JSON.stringify(resSelectedCustomer));
  //     if (this.selectedUidCustomer$ && appCustomerSelected.uid != this.selectedUidCustomer$)


  //     alert('cambio')
  //   });


      // subscribe a cambio periodo
    this.subgetSelectePeriod = this.store
      .pipe(select(getSelectedPeriod))
      .subscribe((res) => {
        this.fromDtPicker$ = res.fromDtPicker;
        this.toDtPicker$ = res.toDtPicker;
        this.timeSpanSelected = res.timespanSel
      });
      // subscribe a CAMBIO Veicoli e Driver
      // scatta quanto ci sono questo diapstch:
      //************************************** */
      // this.store.dispatch(PostActions.getVehiclesOfCustomers());
       //this.store.dispatch(PostActions.getDriversOfCustomers());
       //************************************* */

      this.checkLoadinDataSub = combineLatest([
        this.isLoadingCustomers$,
        this.isLoadingDrivers$
      ]).subscribe(([d1, d2]) => {
        if (!d1 && !d2) {
                if (!this.applyFilterDone && this.tabAssetsChooser ) this.setStoreAssets(this.tabAssetsChooser,this.isCustomerChange);
        }
      });
  }

  ngOnInit(): void {
    this.browserRefresh = browserRefresh;
    locale(this.dataService.getLanguage());

    if (this.browserRefresh) {
      let appSelectedAsset: SelectedAssets = {
        assetType: 'VEHIC',
        selected: [],
        totSelected: 0,
        isApply: false,

      };
      this.store.dispatch(PostActions.setApplySelectedAssets(appSelectedAsset));
    }

    this.stylesheetString = this.utilsService.getStylesheetString();

    this.choosedTab = 0;
    this.applyFilterDone = false;
    this.customers = [];
    let user = this.dataService.getCurrentUser().user;
    if (user.preferences != null)
    this.userUtc = user.preferences.timezone.description;

    this.translateService.get('global.dateFormat').subscribe((dateFormat) => {
      this.dateFormat = dateFormat;
    });

    this.translateService.get('global.dateTimeFormat').subscribe((dateTimeFormat) => {
      this.dateTimeFormat = dateTimeFormat;
    });


    // console.log(this.dateFormat)
    // console.log(this.userUtc)
    this.store.dispatch(PostActions.getVehiclesOfCustomers());
    this.store.dispatch(PostActions.getDriversOfCustomers());
   // this.store.dispatch(PostActions.getTrailersOfCustomers());

    this.getTabAssetsChooser = this.store
    .pipe(select(getTabAssetChooserRefSelector))
    .subscribe((res) => {
    //  console.log('getTabAssetChooserRefSelector', res);
      this.applyFilterDone = false;
      this.tabAssetsChooser =JSON.parse(JSON.stringify(res));
      this.defaultAssetype = this.tabAssetsChooser.defaultAssetType;
      this.titDefaultAssetype = this.tabAssetsChooser.defaultAssetType;

      if (!this.oldSelectionMode)
      {this.oldSelectionMode = res;}

      if (!this.applyFilterDone && this.tabAssetsChooser )
      {
        this.callNewPage = true;
        this.setStoreAssets(this.tabAssetsChooser,false);
      }
    })

  }

  openPanel(event,clickFrom) {
    // this.panelOpen = !this.panelOpen;
    // if (this.panelOpen) {
      // console.log(clickFrom)

    switch (clickFrom) {
      case 'CUSTOMER':
          this.choosedTab = 0;
          break;
      case 'VEHIC':
        this.choosedTab = 1;
        break;
      case  'DRIVR':
        this.choosedTab = 1;
        break;
      case  'TIMESPAN':
          this.choosedTab = 2;
          break;

        default:
          this.choosedTab = 0;
          break;
      }
    // }



    this.applyFilterDone = false;


    this.op.toggle(event);
  }

  onHidePanel() {
    // // non ho applicato i nuovi filtri quindi devo tornare indietro

    if (!this.applyFilterDone) {
      let getSelectedCustomer
      let actualSelectedCustomer
      let oldAssets;

      let getOldCustomerSub: Subscription = this.store
        .pipe(select(getCompleteState))
        .subscribe((resState) => {
        //  console.log('getCompleteState',resState);
          oldAssets = JSON.parse(JSON.stringify(resState.applySelectedAssets));
          getSelectedCustomer = JSON.parse(JSON.stringify(resState.applyCustomer));


        });
      getOldCustomerSub.unsubscribe();

      let getActualCustomerSub: Subscription = this.store
      .pipe(select(selectedCustomerSelector))
      .subscribe((resActualCustomer) => {
     //  console.log('selectedCustomerSelector',resActualCustomer);
       actualSelectedCustomer = JSON.parse(JSON.stringify(resActualCustomer));

      });

      getActualCustomerSub.unsubscribe();

      // se non cambio customer risetto il vecchio valore e ricarico vehicle e drivers
      if (getSelectedCustomer.uid != actualSelectedCustomer.uid)
      {
        this.store.dispatch(PostActions.setSelectedCustomerCache(getSelectedCustomer));
        this.isCustomerChange = false;
        this.store.dispatch(PostActions.getVehiclesOfCustomers());
        this.store.dispatch(PostActions.getDriversOfCustomers());
      }
      else
      // risetto selected assets
      {
        this.store.dispatch(PostActions.setSelectedAssets(oldAssets))
      }









     // this.store.dispatch(PostActions.getTrailersOfCustomers());

      let getOldSelectedPeriod: Subscription = this.store
        .pipe(select(getApplySelectedPeriod))
        .subscribe((resPeriod) => {
          //     // console.log('getApplySelectedAssests',res);
          let getApplySelectedPeriod = JSON.parse(JSON.stringify(resPeriod));
          this.store.dispatch(PostActions.setSelectedPediod(getApplySelectedPeriod));
        });
      getOldSelectedPeriod.unsubscribe();


       let getOldIncludeChildrenSub: Subscription = this.store
      .pipe(select(getCompleteStateIncludeChildrens))
      .subscribe((resIncludeChildrens) => {
        // console.log('getApplySelectedCustomer',res);
        let getSelectedIncludeChildrens = JSON.parse(JSON.stringify(resIncludeChildrens));
        this.store.dispatch(
          PostActions.setCompleteApplyStateIncludeChildrens({ includeChildrens: getSelectedIncludeChildrens })
        );
      });
      getOldIncludeChildrenSub.unsubscribe();



    }

  }

  selectTab(e) {
    this.choosedTab = e.itemData.id;
  }

  applyFilter() {
    this.applyFilterDone = true;
    let applySelectedCustomerSub: Subscription;
    let selectedCustomerFromStore;
    let selectedPeriod;
    applySelectedCustomerSub = this.store
      .pipe(select(selectedCustomerSelector))
      .subscribe((res) => {
        //     console.log('selectedCustomerUidSelector',res);
        selectedCustomerFromStore = JSON.parse(JSON.stringify(res));
        this.store.dispatch(
          PostActions.setApplySelectedCustomer(selectedCustomerFromStore)
        );
      });

    applySelectedCustomerSub.unsubscribe();
    let applySelectedAssetSub: Subscription;
    applySelectedAssetSub = this.store
      .pipe(select(getSelectedAssests))
      .subscribe((resSelectedAssets) => {
        let getSelectedAssets = JSON.parse(JSON.stringify(resSelectedAssets))
        // console.log('getSelectedAssests',res);
        //let selectedAssetsFromStore= JSON.parse(JSON.stringify(res));
        this.appStoreAssetsList.assetType = getSelectedAssets.assetType;
        this.appStoreAssetsList.selected = getSelectedAssets.selected;
        this.appStoreAssetsList.totSelected = getSelectedAssets.totSelected;
        this.appStoreAssetsList.isApply = true;
        this.store.dispatch(
          PostActions.setApplySelectedAssets(JSON.parse(JSON.stringify(this.appStoreAssetsList)))
        );
      });
    applySelectedAssetSub.unsubscribe();

    let applySelectedPeriodSub: Subscription;
    applySelectedPeriodSub = this.store
      .pipe(select(getSelectedPeriod))
      .subscribe((res) => {
        //     console.log('selectedCustomerUidSelector',res);
        selectedPeriod = JSON.parse(JSON.stringify(res));
        this.store.dispatch(PostActions.setApplySelectedPediod(selectedPeriod));
      });
    applySelectedPeriodSub.unsubscribe();
    let appCompleteApplyState: CompleteApplyState = {
      isLoading: true,
      applyCustomer: selectedCustomerFromStore,
      applySelectedAssets: JSON.parse(JSON.stringify(this.appStoreAssetsList)),
      applySelectedPeriod: selectedPeriod,
      includeChildrens:false,
      error: null
    };

    this.store.dispatch(
      PostActions.setCompleteApplyState(appCompleteApplyState)
    );

    this.op.toggle(event);
  }

  onTreeItemClick(e) {
    //console.log(e);
    this.selectedCusdtomerFromTree = e;
    this.loadCustomerData();
  }

  loadCustomerData() {
    if (this.selectedCusdtomerFromTree) {
      this.store.dispatch(
        PostActions.setSelectedCustomerCache(this.selectedCusdtomerFromTree)
      );
      this.isCustomerChange = true;
      this.store.dispatch(PostActions.getVehiclesOfCustomers());
      this.store.dispatch(PostActions.getDriversOfCustomers());
     // this.store.dispatch(PostActions.getTrailersOfCustomers());
      // this.store.dispatch(PostActions.setTabAssetChooserRef(this.tabAssetsChooser));







      // let checkLoadinDataSub:Subscription = combineLatest([
      //   appVechi,
      //   appDriver
      // ]).subscribe(([d1, d2]) => {
      //   if (!d1 && !d2) {
      //     if (!this.applyFilterDone ) this.setStoreAssets(tabChooseIn);
      //   }
      // });
      // checkLoadinDataSub.unsubscribe();





    }
  }



  calcToDate(currentTimespan, from, to) {
    let span: any;

    switch (currentTimespan) {
      case 'timeperiod':
        span = this.utilsService.calcTimeperiod(from, to);

        break;

        case 'dateTimePeriod':
          span = this.utilsService.calcTimeperiod(from, to);
          break;

      case 'daily':
        span = this.utilsService.calcDaily(from);

        break;
      case 'week':
        span = this.utilsService.calcWeek(from);

        break;
      case 'last7days':
        span = this.utilsService.calcLast7Days();

        break;
      case 'month':
        span = this.utilsService.calcMonth(from);

        break;
      default:
        break;
    }

    return span;
  }

  setStoreAssets(asseType,changCustomer) {

   // this.defaultAssetype = asseType.defaultAssetType;
    //this.titDefaultAssetype = asseType.defaultAssetType;
    //let tabAssest = asseType.tabFilterRef;
    let appresApplySel;



    let getOldListAssets: Subscription = this.store
      .pipe(select(getApplySelectedAssests))
      .subscribe((resApplySel) => {
        //console.log('resApplySel', resApplySel);
        appresApplySel = JSON.parse(JSON.stringify(resApplySel));
      });

    getOldListAssets.unsubscribe();
    if (!appresApplySel.isApply) {
      // setto la prima volta  o al realod anche l'apply
      this.setAssestSelected(asseType, true);
      // qui
      this.oldSelectionMode = asseType;
    } else {

      if (asseType.defaultAssetType  !== appresApplySel.assetType)
      {
        //setto anche l'apply per cambio tipologi asset
        if (!changCustomer)
          this.setAssestSelected(asseType, true);
        else
        this.setAssestSelected(asseType, false);
        // qui
        this.oldSelectionMode = asseType;
      } else {

      //  if (changCustomer || !this.applyFilterDone)
      //   {
        if (changCustomer)
        {
              this.setAssestSelected(asseType, false);
            }
            else
            {
              let assetTypeSelectionMode;
              // controllo la selection, se non cambia o è 'all rimetto apply
              if (asseType.tabFilterRef.length > 1)
              assetTypeSelectionMode = asseType.tabFilterRef[1].selectionMode;
              else
                assetTypeSelectionMode = asseType.tabFilterRef[0].selectionMode;

                // 22/10/2023 Elimninato controllo per all e lasciato per tutti in modo da gestire selected sui singoli (report)
              // rimetto apply value
              // if (assetTypeSelectionMode != 'single'){
                 if (this.oldSelectionMode?.tabFilterRef[0].selectionMode == assetTypeSelectionMode)
                 {
                  // gestisco prima volta o refresh con state vuoto e nessuna selezione
                   if (this.appStoreAssetsList.totSelected > 0)
                   {
                      this.appStoreAssetsList.assetType = appresApplySel.assetType;
                      this.appStoreAssetsList.selected = appresApplySel.selected;
                      this.appStoreAssetsList.totSelected = appresApplySel.selected.length;
                      this.appStoreAssetsList.isApply = appresApplySel.isApply
                      this.store.dispatch(PostActions.setSelectedAssets(this.appStoreAssetsList));
                    }
                    else
                    {
                      this.setAssestSelected(asseType, true);
                    }
                    // FZ resettto con nuovo asset type assegnato

                 }

                 else
                 {
                    // risetto per cambio da single a all e viceversa
                    // console.log(this.oldSelectionMode)
                    // console.log(assetTypeSelectionMode)
                    // console.log(appresApplySel)
                   // if (this.oldSelectionMode?.tabFilterRef[0].selectionMode == 'single' && assetTypeSelectionMode == 'all')
                   if (this.oldSelectionMode?.tabFilterRef[0].selectionMode != assetTypeSelectionMode)
                    {
                      //se cambia selezione e ho già selezionato assets, prendo il primo (di quelli selezionati in precedenza)
                      if (appresApplySel.totSelected > 1)
                        {
                          // this.appStoreAssetsList.assetType = appresApplySel.assetType;
                          // this.appStoreAssetsList.selected = [appresApplySel.selected[0]];
                          // this.appStoreAssetsList.totSelected = 1;
                          // this.appStoreAssetsList.isApply = appresApplySel.isApply
                          // this.store.dispatch(PostActions.setSelectedAssets(this.appStoreAssetsList));
                          this.setAssestSelected(asseType, true);
                        }
                      else
                    {
                      this.appStoreAssetsList.assetType = appresApplySel.assetType;
                      this.appStoreAssetsList.selected = appresApplySel.selected;
                      this.appStoreAssetsList.totSelected = appresApplySel.selected.length;
                      this.appStoreAssetsList.isApply = appresApplySel.isApply
                      this.store.dispatch(PostActions.setSelectedAssets(this.appStoreAssetsList));
                    }
                    }
                    else
                    {
                      this.setAssestSelected(asseType, true);
                    }


                    this.oldSelectionMode = asseType;


                 }


            }
      //   }


      //   if (changCustomer || !this.applyFilterDone)
      //   {
      //     // setto solo il selcted (cambio customer )
      //   this.setAssestSelected(asseType, false);
      //   }
      //  else
      //  {
      //   if (this.oldDefaultAssetype != this.defaultAssetype)
      //       this.setAssestSelected(asseType, false);
      //   }
        // ELSE
        // FZ NON FACCIO NULLA PERCHè SONO SULLO STESSO ASSET TYPE

      }



    }
  }
  setAssestSelected(asseType, andApply) {
    let listSetAssets;
    let singleRowAsset:any=[];
    let assetTypeSelectionMode;
    switch (this.defaultAssetype) {
      case 'VEHIC':
          // controllo  selection  mode
        if (asseType.tabFilterRef.length > 1)
          assetTypeSelectionMode = asseType.tabFilterRef[1].selectionMode;
        else assetTypeSelectionMode = asseType.tabFilterRef[0].selectionMode;
        // prendo l'elenco dei veicoli
        let getVehiclesSub: Subscription = this.store
          .pipe(select(getVehiclesOfCustomersSelector))
          .subscribe((resVehi) => {
            //console.log('getVehiclesOfCustomersSelector', resVehi);
            listSetAssets =  JSON.parse(JSON.stringify(resVehi));
            // salvo la singola riga (serve dopo)
            if (resVehi)
              singleRowAsset =listSetAssets[0];
          });
        getVehiclesSub.unsubscribe();

        break;
      case 'DRIVR':
          // controllo  selection  mode
        if (asseType.tabFilterRef.length > 1)
          assetTypeSelectionMode = asseType.tabFilterRef[1].selectionMode;
        else assetTypeSelectionMode = asseType.tabFilterRef[0].selectionMode;

        // prendo l'elenco dei driver
        let getDriversSub: Subscription = this.store
          .pipe(select(getDriversOfCustomersSelector))
          .subscribe((resDriver) => {
            //console.log('getDriversOfCustomersSelector', resDriver);
            listSetAssets = JSON.parse(JSON.stringify(resDriver));
          // salvo la singola riga (serve dopo)
            if (resDriver)
              singleRowAsset = listSetAssets[0];
          });
        getDriversSub.unsubscribe();
        break;
    }
    if (assetTypeSelectionMode == 'all') {
      // se tutti salvor array con tutti gli assets selezionati
      this.appStoreAssetsList.assetType = asseType.defaultAssetType;
      this.appStoreAssetsList.selected = listSetAssets;
      this.appStoreAssetsList.totSelected = listSetAssets.length;

      //this.store.dispatch(PostActions.setSelectedAssets(this.appStoreAssetsList ));
    } else {
      // controllo se sono in cambio customer lascio riga zero default
      if (!this.isCustomerChange)
      //altrimenti
      {
      // prendo vecchia selezione e verifico, se c'è mantengo quella
      let selectedAssets;
      let getDriversApplySelectedSub: Subscription = this.store
      .pipe(select(getSelectedAssests))
      .subscribe((resSelcetedAsset) => {
        //console.log('getDriversOfCustomersSelector', resDriver);
        selectedAssets = JSON.parse(JSON.stringify(resSelcetedAsset));

        if (selectedAssets.assetType == this.defaultAssetype &&  selectedAssets.selected.length > 0 )
        {
          singleRowAsset =  selectedAssets.selected[0]
        }
      });
      getDriversApplySelectedSub.unsubscribe();
    }
      // setto singola riga
      let singleRowArray:any = [];
      if (singleRowAsset != null)
      {
      singleRowArray = [singleRowAsset];
      }
      this.appStoreAssetsList.assetType = asseType.defaultAssetType;
      this.appStoreAssetsList.selected = singleRowArray;
      this.appStoreAssetsList.totSelected = singleRowArray.length;

      //this.store.dispatch(PostActions.setSelectedAssets(this.appStoreAssetsList ));
    }
    // setto i selected e scattano aventi per sleezioni temp sui filtri

    this.store.dispatch(PostActions.setSelectedAssets(this.appStoreAssetsList));
    if (andApply) {
      let appStoreApply =this.appStoreAssetsList;
    //  this.store.dispatch(PostActions.setSelectedAssets(appStoreApply));
      appStoreApply.isApply = andApply;
      // setto apply
      this.store.dispatch(PostActions.setApplySelectedAssets(appStoreApply));

      let appLastPeriod;
      let getOldSelectedPeriod: Subscription = this.store
      .pipe(select(getApplySelectedPeriod))
      .subscribe((resPeriod) => {
        appLastPeriod = JSON.parse(JSON.stringify(resPeriod));

        // verificare da qui il refresh
      });
    getOldSelectedPeriod.unsubscribe();



      let appCompleteApplyState: CompleteApplyState = {
        isLoading: true,
        applyCustomer: this.selectedStoreCustomer,
        applySelectedAssets: JSON.parse(JSON.stringify(this.appStoreAssetsList)),
        applySelectedPeriod: appLastPeriod,
        includeChildrens:false,
        error: null
      };
      // se set apply == true setto anche apply completo di tutot lo state. Questo è utilizzato dai singoli componenti in quanto scatta una volta sola QUI
      this.store.dispatch(
        PostActions.setCompleteApplyState(appCompleteApplyState)
      );



    }

  }

  setIncludeChildrenValue(e) {
    this.includeChildrenValue = e.value;

    this.store.dispatch(
      PostActions.setCompleteApplyStateIncludeChildrens({ includeChildrens: e.value })
    );


  }


  ngOnDestroy(): void {
    if( this.subgetTabFilterRefSelector)
      this.subgetTabFilterRefSelector.unsubscribe();
    if (this.subgetSelectedAssests)
      this.subgetSelectedAssests.unsubscribe();
    if (this.subgetSelectePeriod)
      this.subgetSelectePeriod.unsubscribe();
    if (this.getTabAssetsChooser)
    this.getTabAssetsChooser.unsubscribe();
  if (this.checkLoadinDataSub)
    this.checkLoadinDataSub.unsubscribe();
    //this.subgetSelectedCustomer.unsubscribe();
   }


}
