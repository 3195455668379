<section [id]="idComponent">
  <div class="d-flex">
    <div><strong>{{functionBarTitle}}</strong></div>

    <div class="pull-right">
      <span class="pull-right hidden-xs">
        <a (click)="refresh()" class="btn btn-square-xs btn-default btn-xs element-refresh" pTooltip="{{tootipRefresh}}"
          tooltipPosition="left" *ngIf="showButtonRefresh">
          <fa-icon [icon]="faRefresh"></fa-icon>
        </a>
        <a class="btn btn-default btn-xs btn-square-xs ml-1" (click)="exportGrid()" pTooltip="{{tootipExport}}"
          tooltipPosition="left" *ngIf="showButtonExport">
          <fa-icon [icon]="faFileExcel"></fa-icon>
        </a>
        <a (click)="openAddPanel()" class="btn btn-default btn-square-xs btn-xs ml-1 " pTooltip="{{tootipNew}}"
          tooltipPosition="left" *ngIf="showButtonNew">
          <fa-icon [icon]="faPlus"></fa-icon>
        </a>
        <!-- Pair Trailers -->
        <a (click)="pairTrailer()" class="btn btn-default btn-square-xs btn-xs ml-1 element-pairs"
          pTooltip="{{tooltipPairTrailer}}" tooltipPosition="left" *ngIf="showButtonPair">
          <fa-icon [icon]="faPairTrailer"></fa-icon>
        </a>
      </span>
    </div>
  </div>
</section>