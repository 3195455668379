import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs';
import { faCoffee, faRefresh, faPlus, faFileExcel, faTrailer, faTruckArrowRight } from '@fortawesome/free-solid-svg-icons';





@Component({
  selector: 'actia-tableFunctionBar',
  templateUrl: './tableFunctionBar.component.html',
  styleUrls: ['./tableFunctionBar.component.scss']
})



export class ActiaTableFunctionBarComponent implements OnInit {
  @Input() idComponent: string = '';
  @Input() functionBarTitle: string = '';
  @Input() showButtonRefresh: boolean = true;
  @Input() showButtonExport: boolean = true;
  @Input() showButtonNew: boolean = true;
  @Input() showButtonNewRestore: string = '';
  @Input() showButtonPair: boolean = false;
  @Input() tootipRefresh: string = '';
  @Input() tootipExport: string = '';
  @Input() tootipNew: string = '';
  @Input() tooltipPairTrailer: string = '';

  @Output() onButtonExportGridEvent = new EventEmitter()
  @Output() onButtonRefreshEvent = new EventEmitter()
  @Output() onButtonOpenAddPanelEvent = new EventEmitter()
  @Output() onButtonPairTrailer = new EventEmitter()

  faCoffee = faCoffee;
  faRefresh = faRefresh;
  faFileExcel = faFileExcel;
  faPlus = faPlus;
  faPairTrailer = faTruckArrowRight;
  // faRefresh = REFRESH_ICON;
  // faFileExcel = FILE_EXCEL_ICON;
  // faPlus = PLUS_ICON;
  // faCheck = CHECK_ICON;


  constructor() {

  }


  ngOnInit(): void {



  }


  refresh() {
    this.onButtonRefreshEvent.emit();

  }

  exportGrid() {

    this.onButtonExportGridEvent.emit()

  }

  openAddPanel() {
    this.onButtonOpenAddPanelEvent.emit()

  }

  pairTrailer() {
    this.onButtonPairTrailer.emit();
  }


}




