import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  faClose,
  faFloppyDisk,
  faListSquares,
  faTrash,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { JsonEditorOptions } from 'ang-jsoneditor';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { locale } from 'devextreme/localization';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject, merge } from 'rxjs';
import { DataService } from 'src/app/core/services/data.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { VehicleService } from 'src/app/pages/management/vehicle/vehicle.service';

// const enterTransition = transition(':enter',[
//   style({
//     opacity:0,

//     'color': 'rgba(0, 140, 149, 0.7137254902)',
//     'box-shadow': '-3px 0 6px rgba(0, 0, 0, 0.1607843137)',



//   }),
//   animate('1s ease-in',style({opacity:1}))
// ])
// const fadeIn = trigger('fadeIn',[enterTransition])

const enterTransition = transition(':enter',[
  style({
    opacity:0,
    'color': 'rgba(0, 140, 149, 0.7137254902)',
    'box-shadow': '-3px 0 6px rgba(0, 0, 0, 0.1607843137)',
     transform: 'translateX(100%)'

    }),
  animate('600ms ease-in',
  style({
    opacity:1,
    transform: 'translateX(0%)'
  })),
])
const fadeIn = trigger('fadeIn',[enterTransition])



@Component({
  selector: 'app-pairs-trailers-modal',
  templateUrl: './pairs-trailers-modal.component.html',
  styleUrls: ['./pairs-trailers-modal.component.scss'],
  animations:[fadeIn]
})
export class PairsTrailersModalComponent implements OnInit {

  constructor(
    private utilsService: UtilsService,
    private cdr: ChangeDetectorRef,
    public translate: TranslateService,
    public vehicleSer: VehicleService,
    private dataService: DataService
  ) {

    this.translate.setDefaultLang(this.dataService.getLanguage());

  }

  @Input()
  get listTrailerIn(): any {
    return this._listTrailerIn;
  }
  set listTrailerIn(value: any) {
    // console.log('listTrailerIn set', value);
    if (value)
    {
    this._listTrailerIn = [...value];
    this.listTrailersBck = [...value];

    this.listTag = [];
    for (let i = 0; i < this.listTrailersBck.length; i++) {
        this.listTag.push({id:i,tag:''})
    }

    }

  }
  private _listTrailerIn: any;




  @Input()
  get listPairsIn(): any {
    return this._listPairsIn;
  }
  set listPairsIn(value: any) {

    this._listPairsIn = [...value];
    this.listPairsNew =  [...value];
    this.singleTrailers = [];

  }
  private _listPairsIn: any;

  @Input()
  get listVehicleIn(): any {
    return this._listVehicleIn;
  }
  set listVehicleIn(value: any) {

    // per settare paginazione

    // this._listVehicleIn =  new DataSource({
    //   store: new ArrayStore({

    //     data: value
    //   }),
    //   // paginate: editedApiDataStore.length > 10 ? true : false,
    //   paginate: true,
    //    pageSize: 15,

    // });



    // console.log(this._listVehicleIn)
    this._listVehicleIn = value;
    this.listVeichileBck =  [...value]
  }
  private _listVehicleIn: any;

  @Output() onSaveButtonEvent = new EventEmitter()

  @Output() onCloseButtonEvent = new EventEmitter()



  allowDeletion = false;

  itemDeleteMode = 'toggle';
  scrollByContent = true;

  scrollByThumb = true;
  pageSize = 10;
  allowedPageSizes = [10, 20, 40];
  scrollbarMode: string;
  pullDown: boolean = false;
  stylesheetString = 'aft';

  selectionModeValue = 'single';

  selectedTrailer;
  seletedVehicle;
  listPairsNew: any = [];
  listTag:any = [];

  faSave = faFloppyDisk;
  faCancel = faTrash;
  faClose = faXmark;


  onDataLoadMsg: string;
  dateTimeFormat: string;

  tooltipSave='';
  tooltipCancel='';

  listTrailersBck;
  listVeichileBck;

  singleTrailers:any=[];
  singleTag:string;
  notextData:string;
  @Input()
  get windowH(): any {
    return this._windowH;
  }
  set windowH(value: any) {

    this._windowH = value;


  }
  private _windowH: string;


  @Input() windowW: string = '50vh';

  passwordButton: any;


  ngOnInit(): void {
    this.stylesheetString = this.utilsService.getStylesheetString();
    locale(this.dataService.getLanguage());
    this.dateTimeFormat = this.translate.instant('global.dateTimeFormat');
    this.notextData = this.translate.instant('trailerPage.noDataTodDisplay');




  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngAfterViewInit(): void {
    // Additional styling for custom wizard toolbar & body

    if (this.stylesheetString != 'aft')
      (document.querySelector('#ngwizard') as HTMLElement).classList.add(
        this.stylesheetString
      );
      this.singleTrailers = [];
  }

  listTrailersColSelectionChanged(e) {

    this.selectedTrailer = e.itemData;
    this.createListPairs();
  }

  listlistVehiclesColSelectionChanged(e) {

    this.seletedVehicle = e.itemData;
    this.createListPairs();
  }

  createListPairs() {
    if (this.selectedTrailer && this.seletedVehicle) {

      if (this.seletedVehicle.tag)
      this.listPairsNew.unshift({
        trailer: this.selectedTrailer,
        tag: this.seletedVehicle
      });
      else
      {
      this.listPairsNew.unshift({
        trailer: this.selectedTrailer,
        vehicle: this.seletedVehicle
      });

        // elimino elemento dalla lista Vehicle
        const indexOfVehicle = this.listVehicleIn.findIndex((object) => {
          return object.vin === this.seletedVehicle.vin;
        });
        this.listVehicleIn.splice(indexOfVehicle, 1);


      }
      // elimino elemento dalla lista Trailer
      let indexOfTrailer = this.listTrailerIn.findIndex((object) => {
        return object.vin === this.selectedTrailer.vin;
      });
      if (indexOfTrailer != -1)
            this.listTrailerIn.splice(indexOfTrailer, 1);



      this.selectedTrailer = null;
      this.seletedVehicle = null;

    }
  }

  unPairsRow(e){
    this.listTrailerIn.push(e.trailer)
     if (e.vehicle)
      this.listVehicleIn.push(e.vehicle)

    if (e.vehicle)
    {
       // elimino elemento dalla lista Pairs
       let  indexOfPairs = this.listPairsNew.findIndex((object) => {
        return object.trailer.vin === e.trailer.vin && object.vehicle.vin === e.vehicle.vin;
      });

      this.listPairsNew.splice(indexOfPairs, 1);
    }
    else
    {
  // elimino elemento dalla lista Pairs non contando il veihicle perchè è un tag

        let  indexOfPairs = this.listPairsNew.findIndex((object) => {
          return object.trailer.vin === e.trailer.vin ;
        });

        this.listPairsNew.splice(indexOfPairs, 1);

      // ho solo elenco di tag e quindi aggiungo una riga
      if (this.listVehicleIn.length == 0)
      {
        this.listTag.push({id:this.listTag + 1,tag:''})
      }

    }

  }

  savePairs(){

    this.onSaveButtonEvent.emit(this.listPairsNew)
  }


  cancelPairs(){
    this.listPairsNew = [];

    this.listTrailerIn = this.listTrailersBck;
    // aggiungo eventualmente quelli accoppiati in precedenza
    for (let oldPairs of this.listPairsIn)
    {
      this.listTrailerIn.push(oldPairs.trailer)
    }

    this.listVehicleIn = this.listVeichileBck;


  }

  closeDialog(){
    this.onCloseButtonEvent.emit()
  }



  onTrailerSelect(t){
    //console.log('onTrailerSelect',t)
    if (this.singleTrailers.length > 0)
    {
      this.listTrailerIn.push(this.singleTrailers[0])
      this.singleTrailers = [];
    }
    this.singleTrailers.push(t)

    const indexOfTrailer = this.listTrailerIn.findIndex((object) => {
      return object.vin === t.vin;
    });
    this.listTrailerIn.splice(indexOfTrailer, 1);



  }

  onCancelSingleTrailerSelect(t)
  {
    this.listTrailerIn.push(this.singleTrailers[0] )
    this.singleTrailers = [];
    this.singleTag = '';

  }

  pairSingleTrailerAndTag(){
    this.selectedTrailer = this.singleTrailers[0];
    this.seletedVehicle = {tag:this.singleTag};

    this.createListPairs();
    this.singleTrailers = [];
    this.singleTag = '';
  }

  pairListTagAndTrailer(index){



    const inputElement = document.getElementById("tag-"+index) as HTMLInputElement;
    const inputValue = inputElement.value;

    if (inputValue)
    {
    // console.log("Input value:", inputValue);
    // console.log(this.listTrailerIn[index])

    this.selectedTrailer = this.listTrailerIn[index];
    this.seletedVehicle = {tag:inputValue};
    this.createListPairs();
    this.listTag.splice(index, 1);
  }



  }


}
