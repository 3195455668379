<div class="header-title-wrapper d-flex">
  <span class="aft-page-header__title" [translate]="headTitle"></span>
  <div primo class="pull-right ">
    <a  (click)="openHelpPopup()" *ngIf="help" class="btn btn-square-xs btn-default btn-xs help-btn help-element"
    pTooltip="{{'global.help' | translate }}" tooltipPosition="left">
      <fa-icon [icon]="faHelp"></fa-icon>
    </a>
    <a (click)="openSearchAsset()" *ngIf="searchAssetEnable" class="btn btn-square-xs btn-default btn-xs ml-1"
    pTooltip="{{'global.searchAsset' | translate }}" tooltipPosition="left">
      <fa-icon [icon]="faBinoculars"></fa-icon>
    </a>
  </div>
</div>
<!--
<div class="aft-page-header__filter-recap">

  <section *ngIf="isAssetsVisible">

    <i *ngIf="assetsObject.type === 'driver' || assetsObject.type === 'teams'" [ngClass]="assetsObject.icon"></i>
    <fa-icon *ngIf="assetsObject.type == 'fleets' || assetsObject.type == 'vehicle'" [icon]="assetsObject.icon">
    </fa-icon>
    <strong> ({{ assetsObject.length }})</strong>
  </section>
  <section *ngIf="isTimespanVisible">
    <span class="mx-1">{{ translateService.instant('global.timeperiod') }}:</span>
    <strong [translate]="'global.' + timespanObject.timespan"></strong>
    <span class="mx-1">{{ translateService.instant('global.from') }}:</span>
    <strong>{{ timespanObject.from }}</strong>
    <span class="mx-1">{{ translateService.instant('global.to') }}:</span>
    <strong>{{ timespanObject.to }}</strong>
  </section>
</div>
 -->
