import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-replice-confirmation-modal',
  templateUrl: './replice-confirmation-modal.component.html',
  styleUrls: ['./replice-confirmation-modal.component.scss']
})
export class RepliceConfirmationModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<RepliceConfirmationModalComponent>, @Inject(MAT_DIALOG_DATA) public data: string) { }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  noReply(){
    this.dialogRef.close('no');
  }

  save(){
    this.dialogRef.close('yes');
  }
}
