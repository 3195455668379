import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, EMPTY, map, Observable, ReplaySubject, retryWhen } from 'rxjs';
import { CURRENT_USER_ENDPOINT, ENGINE_TYPES_ENDPOINT, INSTALLATIONS_TYPES_ENDPOINT, MISCELLANEUS_ENDPOINT, VEHICLE_TYPES_ENDPOINT, Vehicules_BY_CUSTOMER_ENDPOINT, VERSION_BAG_ENDPOINT } from 'src/app/core/config/config.constants';
import { CurrentUserInfo } from 'src/app/core/models/currentUserInfo';
import { KeyValueBag } from 'src/app/core/models/keyValueBag';
import { VehicleRestricted } from 'src/app/core/models/vehicleRestricted';
import { UtilsService } from 'src/app/core/services/utils.service';
import { FilterService } from 'src/app/shared/components/filter/filter.service';
import { WarningModalComponent } from 'src/app/shared/components/warning-modal/warning-modal.component';

import { environment } from 'src/environments/environment';
import { BaseNameUidRoot } from './model/BaseNameUidRoot.model';
import notify from 'devextreme/ui/notify';
import { SelectedCustomerCacheInterface } from 'src/app/core/store/interface/selectedCustomerCache.interface';




@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(private http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
    private utilsService: UtilsService,

    private spinner: NgxSpinnerService,) { }

    public listVehicleType$ = new ReplaySubject<BaseNameUidRoot[] | null>(1);

  baseApi = environment.baseApi;


  private _jsonURL = 'assets/data/miscellaneous.data.json';

  public getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }

  getMiscellaneusData$(dataParameters: any, type: string): Observable<any> {
    return this.http
      .post<any>(this.baseApi + MISCELLANEUS_ENDPOINT + type, dataParameters)
      .pipe(
        map((Payload) => {
          //console.log(Payload)
          return Payload;
        }),
        catchError((err: any) => {
          return err;
        })
      );
  }

   getVehicleTypes$():Observable<KeyValueBag[]>{

    return this.http.get<KeyValueBag[]>(
      `${this.baseApi + VEHICLE_TYPES_ENDPOINT}`
    ).pipe(
      map((Payload) => {

        return Payload
        }),
      catchError((err: HttpErrorResponse) => {
        this.utilsService.showErrorPopup(err);

        return EMPTY;
      }),

    )
  }

  getEngineTypes$():Observable<KeyValueBag[]>{

    return this.http.get<KeyValueBag[]>(
      `${this.baseApi + ENGINE_TYPES_ENDPOINT}`
    ).pipe(
      map((Payload) => {

        return Payload
        }),
      catchError((err: HttpErrorResponse) => {
        this.utilsService.showErrorPopup(err);

        return EMPTY;
      }),

    )
  }

  getInstallationTypes$():Observable<BaseNameUidRoot[]>{

    return this.http.get<BaseNameUidRoot[]>(
      `${this.baseApi + INSTALLATIONS_TYPES_ENDPOINT}`
    ).pipe(
      map((Payload) => {

        return Payload
        }),
      catchError((err: HttpErrorResponse) => {
        this.utilsService.showErrorPopup(err);

        return EMPTY;
      }),

    )
  }

  getVehiclesOfCustomer$(customer:SelectedCustomerCacheInterface):Observable<VehicleRestricted[]>{
    this.spinner.show()
    return this.http.get<VehicleRestricted[]>(
      `${this.baseApi + Vehicules_BY_CUSTOMER_ENDPOINT+ "/" + customer.uid + "?alsoDisabled=true&withFullHistory=false"}`
    ).pipe(
      map((Payload) => {
        this.spinner.hide()
        return Payload

        }),
      catchError((err: HttpErrorResponse) => {
        this.utilsService.showErrorPopup(err);
        this.spinner.hide()
        // let message = err.error.Message
        // console.log(err.error)
        // notify({
        //   message,
        //   width: 450,
        // },
        // 'error',
        // 2000);
        return EMPTY;
      }
      ),

    )
  }

  getSelectedVehicle$(vehicleUid: any,customer:SelectedCustomerCacheInterface) {

    return this.http.get<VehicleRestricted[]>(
      `${this.baseApi + Vehicules_BY_CUSTOMER_ENDPOINT+ "/" + customer.uid + "/vehicle/" +vehicleUid}`
    ).pipe(
      map((Payload) => {

        return Payload

        }),
      catchError((err: HttpErrorResponse) => {
        this.utilsService.showErrorPopup(err);

        return EMPTY;
      }),

    )

  }





}
