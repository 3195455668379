import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { KeycloakAuthService } from './keycloak.auth.service';
import { CACHE_KEY } from '../config/config.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(

    private keycloakAuthService: KeycloakAuthService
  ) {}

  logOut() {
    caches.delete(CACHE_KEY);
    let token = sessionStorage.getItem('tokenLoginAs');
    localStorage.clear();
    if (token) {
      localStorage.removeItem('tokenLoginAs');
    } else {
      this.keycloakAuthService.logout();
    }
  }

  isAuthenticated() {
    let token = sessionStorage.getItem('tokenLoginAs');
    if (token) {
      return true;
    }
    return this.keycloakAuthService.isAuthenticated();
  }

  isFeatureGranted(functionalityName: any, level: any) {
    return this.keycloakAuthService.isFeatureGranted(functionalityName, level);
  }

  // Check if user has write grant on provided menu label name
  isMenuLabelWriteGranted(menuLabelName) {
    return this.keycloakAuthService.isMenuLabelWriteGranted(menuLabelName);
  }

  // Check if user has read grant on provided menu label name
  isMenuLabelReadGranted(menuLabelName) {
    return this.keycloakAuthService.isMenuLabelReadGranted(menuLabelName);
  }
}
