<dx-tree-view
    #treeView2
    [dataSource]="customers"
    displayExpr= "nameWithChildren"
    parentIdExpr= "uidParent"
    keyExpr= "uid"
    dataStructure="plain"
    [noDataText]="'global.noDataText' | translate"
    [searchEnabled]="true"
    (onContentReady)="onContentReady($event)"
    (onItemRendered)="onItemRendered($event)"
    searchMode="contains"
    [focusStateEnabled]="true"
    (onItemExpanded)="onItemCollapseAndExpanded($event)"
    (onItemCollapsed)="onItemCollapseAndExpanded($event)"
    [searchEditorOptions]="{'placeholder': ('global.search' | translate)}"
    (onItemClick)="onItemClick($event)">
</dx-tree-view>