<div class="card" [ngStyle]="{'border': data.type === 'e' ? '#b50000 !important' : '#099099 !important'}">
  <div class="card-header" [ngStyle]="{'background-color': data.type === 'e' ? '#b50000' : '#099099'}">
    <div class="circle-icon">
      <i *ngIf="data.type === 'o'" class="fa-solid fa-check"></i>
      <i *ngIf="data.type === 'i'" class="fa-solid fa-info iconStyle"></i>
      <i *ngIf="data.type === 'e'" class="fa-solid fa-xmark iconError"></i>
    </div>
    <span class="font-bold white-space-nowrap intestazioni">{{data.title | translate}}</span>
  </div>
  <div class="card-body">
    <p class="card-text textBody">{{data.text | translate}}</p>
  </div>
  <div class="flex justify-end p-2 footerBox">
    <div class="pl-2">
      <button pButton pRipple type="button" icon="pi pi-check" (click)="close()"> ok</button>
    </div>
  </div>
</div>
<!-- Modal HTML -->
<!--<div id="myModal" class="modal fade">
  <div class="modal-dialog modal-confirm" [ngStyle]="{'border-color': data.type === 'e' ? '#b50000' : '#099099'}">
    <div class="modal-content">
      <div class="modal-header">
        <div class="icon-box"[ngStyle]="{'background': data.type === 'e' ? '#b50000' : '#099099'}">
          <i *ngIf="data.type === 'o'" class="fa-solid fa-check"></i>
          <i *ngIf="data.type === 'i'" class="fa-solid fa-info iconStyle"></i>
          <i *ngIf="data.type === 'e'" class="fa-solid fa-xmark iconError"></i>
        </div>
        <h4 class="modal-title w-100">{{data.title | translate}}</h4>
      </div>
      <div class="modal-body">
        <p class="text-center">{{data.text | translate}}</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success btn-block" [ngStyle]="{'background': data.type === 'e' ? '#b50000' : '#099099'}" icon="pi pi-check" (click)="close()">OK</button>
      </div>
    </div>
  </div>
</div>-->