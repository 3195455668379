<div class="card">
    <h3 class="card-header">{{"login.privacy" | translate}}</h3>
    <div class="card-body">
        <h4>{{"login.cookies.howWeUse" | translate}}</h4>
        <p>{{"login.cookies.by" | translate}}</p>
        <h4>{{"login.cookies.whatAre" | translate}}</h4>
        <p>{{"login.cookies.small" | translate}}</p>
        <h4>{{"login.cookies.when" | translate}}</h4>
        <div>
            <p>{{"login.cookies.enhance" | translate}}</p>
            <ul>
                <li>{{"login.cookies.language" | translate}}</li>
                <li>{{"login.cookies.validated" | translate}}</li>
                <li>{{"login.cookies.selected" | translate}}</li>
            </ul>
        </div>
        <h4>{{"login.cookies.check" | translate}}</h4>
        <p>{{"login.cookies.delivery" | translate}}
            <a target="_new" href="https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies">Google Chrome</a>,
            <a target="_new" href="https://support.mozilla.org/t5/Cookies-and-cache/Attivare-e-disattivare-i-cookie/ta-p/7656?redirectlocale=en-US&redirectslug=Enabling+and+disabling+cookies"> Mozilla Firefox</a>,
            <a target="_new" href="https://support.apple.com/kb/PH19214?viewlocale=en_US&locale=en_US">Apple Safari</a> e
            <a target="_new" href="https://support.microsoft.com/en-en/help/17442/windows-internet-explorer-delete-manage-cookies">Microsoft Internet Explorer</a>.
        </p>
    </div>
    <div class="card-footer">
        <a (click)="close()" class="btn btn-ok">{{"global.modal.ok" | translate}}</a>
    </div>
</div> 