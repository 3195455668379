import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { faCheck, faDoorOpen,faDoorClosed,faPowerOff  } from '@fortawesome/free-solid-svg-icons';
import { DxDataGridComponent } from 'devextreme-angular';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/core/services/data.service';




@Component({
  selector: 'actia-tableDevExtreme',
  templateUrl: './tableDevExtreme.component.html',
  styleUrls: ['./tableDevExtreme.component.scss']
})

export class ActiaTableDevExtremeComponent implements OnInit  {

    constructor(
      private cdr: ChangeDetectorRef,
      private translate: TranslateService,
      private dataService : DataService) {
     }

    // for documentation about component see directly Dev Extreme Table https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/Overview/Angular/Light/
    //  @Input() dataTable:any;
    @Input() dataCols:any;

    @Input()
    get dataTable(): any { return this._dataTable; }
    set dataTable(value: any) {
      this._dataTable = value;
    }
    private _dataTable:any;

    private _selectedRows: any[] =[]

    public get selectedRows(): any[] {

        return this._selectedRows
    }
    public set selectedRows(value: any[]) {
     // console.log('selectedRows',value)
        this._selectedRows = value
        this.onSelectedRows.emit(value)
    }
    //@Input() writeGrant:boolean=false;

    //#FZ NB. se non passo inkeyExpr la tabelle restituisce un array di elementi e non le Key
    // PROPERTIES
    @Input() idComponent:string ='';
    @Input() componentTitle = ''
    @Input() inkeyExpr:any = ''
    @Input() showBorders:boolean=true;
    @Input() allowColumnReordering:boolean=true;
    @Input() allowColumnResizing:boolean=true;
    @Input() rowAlternationEnabled:boolean=true;
    @Input() showRowLines:boolean=true;
    @Input() selectAllMode:string = 'allPages';
    @Input() pagerVisibile:boolean = true;
    @Input() pageSize:number=20;
    @Input() allowedPageSizes=[20, 40, 60];
    @Input() showPageSizeSelector:boolean=true;
    @Input() showSearch:boolean=true;
    @Input() searchWidth:number= 200;
    @Input() displayMode:string='full';
    @Input() showInfo:boolean=true;
    @Input() showNavigationButtons:boolean=true;
    @Input() enabledExportButton:boolean=false;
    @Input() selectMode:string='multiple';

    //EVENTS
    @Output() onSelectedRows = new EventEmitter()
    @Output() gridComponent = new EventEmitter()

    //METODS

    @Input() tableRefresh: any = Observable;

    // gestione eventi da ricevere
    private subscriptions = new Subscription()
    displayFormatDate:string;
    displayFormatDateTime:string;
    dateTimeSecondsFormat:string;
    userUtc:string;

    faCheck = faCheck;
    faDoorOpen = faDoorOpen;
    faDoorClose = faDoorClosed;
    faPower = faPowerOff;

    @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent | undefined;
    ngOnInit(): void {

      this.translate.setDefaultLang(this.dataService.getLanguage());
      let user = this.dataService.getCurrentUser().user;
      this.userUtc = user.preferences.timezone.description;
      this.translate.get('global.dateFormat').subscribe(dateFormat => {
        this.displayFormatDate = dateFormat;
      });
      this.translate.get('global.dateTimeFormat').subscribe(dateTimeFormat => {
        this.displayFormatDateTime = dateTimeFormat;

      });

      this.translate.get('global.dateTimeSecondsFormat').subscribe(dateTimeSecondsFormat => {
        this.dateTimeSecondsFormat = dateTimeSecondsFormat;

      });




      this.subscriptions.add(this.tableRefresh.subscribe(this.refresh()))
     // this.gridComponent.emit(this.grid)
    /// console.log('ngOnInit',this.dataTable)

   }


   ngAfterContentInit(){
     //console.log('ngAfterContentInit',this.dataTable)
     //console.log(this.grid)
     //#FZ espongo l'oggetto completo
     //this.grid?.instance.exportToExcel(true)
     this.gridComponent.emit(this.grid)
    }


    refresh()  {
      console.log('refresh, do something')

      this.grid?.instance.deselectAll();

  }



  ngOnDestroy() {
    // sgancio il componente  da tutti i servizi in observable
    this.subscriptions.unsubscribe();
  }





}




