import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { ApiService } from './app/core/services/api.service';
import { UtilsService } from './app/core/services/utils.service';
import { mapRealm } from './environments/environment';

export function initializer(
  keycloak: KeycloakService,
  router: Router,
  utilsService: UtilsService,
  apiService: ApiService
): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        let domain = window.location.hostname;
        let keycloakConfig = mapRealm.get(domain);
        if (keycloakConfig?.config) {
          await keycloak.init({
            config: keycloakConfig.config,
            loadUserProfileAtStartUp: false,
            initOptions: {
              checkLoginIframe: false,
              onLoad: 'check-sso',
              flow: keycloakConfig.flow,
              silentCheckSsoRedirectUri:
                window.location.origin + '/assets/silent-check-sso.html'
            },
            enableBearerInterceptor: true,
            bearerExcludedUrls: ['/assets','blob.core.windows.net/fmw']
          });
          resolve(true);
        } else {
          resolve(false);
          utilsService.error = { status: 404 };
          router.navigate(['/error']);
          //throw new Error('Realm missing or unknown');
        }
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };
}
