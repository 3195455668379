import { createAction, props } from '@ngrx/store';
import { SelectedCustomerCacheInterface } from './interface/selectedCustomerCache.interface';
import { ListTabRefInterface, TabFilterRefInterface } from './interface/tabFilterRef.interface';
import { CurrentUserInfo } from '../models/currentUserInfo';
import { SelectedAssets } from './interface/selectedAssets.interface';
import { SelectedPeriodFitler } from './interface/selectedPeriod.interface';
import { CompleteApplyState } from './interface/completeApplyState.interface';
import { FilterRefSetting } from './interface/filterRefSetting.interface';

export const getCustomers = createAction('[getCustomers] Get Customers');

export const getSuccesCustomers = createAction(
  '[getCustomers] Get  Customers success',
  props<{ posts: [] }>()
);
export const getFailureCustomers = createAction(
  '[getCustomers] Get  Customers failure',
  props<{ error: string }>()
);

export const getVehiclesOfCustomers = createAction(
  '[getVehiclesOfCustomers] Get VehiclesOfCustomers'
);

export const getSuccesVehiclesOfCustomers = createAction(
  '[getVehiclesOfCustomers] Get  VehiclesOfCustomers success',
  props<{ posts: [] }>()
);
export const getFailureVehiclesOfCustomers = createAction(
  '[getVehiclesOfCustomers] Get  VehiclesOfCustomers failure',
  props<{ error: string }>()
);

export const getTrailersOfCustomers = createAction(
  '[getTrailersOfCustomers] Get TrailersOfCustomers'
);

export const getSuccesTrailersOfCustomers = createAction(
  '[getTrailersOfCustomers] Get  TrailersOfCustomers success',
  props<{ posts: [] }>()
);
export const getFailureTrailersOfCustomers = createAction(
  '[getTrailersOfCustomers] Get  TrailersOfCustomers failure',
  props<{ error: string }>()
);



export const getFleetsOfCustomers = createAction(
  '[getFleetsOfCustomers] Get FleetsOfCustofvmers'
);

export const getSuccesFleetsOfCustomers = createAction(
  '[getFleetsOfCustomers] Get FleetsOfCustomers success',
  props<{ posts: [] }>()
);
export const getFailureFleetsOfCustomers = createAction(
  '[getFleetsOfCustomers] Get FleetsOfCustomers failure',
  props<{ error: string }>()
);

export const getDriversOfCustomers = createAction(
  '[getDriversOfCustomers] Get DriversOfCustomers'
);

export const getSuccesDriversOfCustomers = createAction(
  '[getDriversOfCustomers] Get DriversOfCustomers success',
  props<{ posts: [] }>()
);
export const getFailureDriversOfCustomers = createAction(
  '[getDriversOfCustomers] Get DriversOfCustomers failure',
  props<{ error: string }>()
);

export const getTeamsOfCustomers = createAction(
  '[getTeamsOfCustomers] Get DriversOfCustomers'
);

export const getSuccesTeamsOfCustomers = createAction(
  '[getTeamsOfCustomers] Get DriversOfCustomers success',
  props<{ posts: [] }>()
);
export const getFailureTeamsOfCustomers = createAction(
  '[getTeamsOfCustomers] Get DriversOfCustomers failure',
  props<{ error: string }>()
);


export const setSelectedCustomerCache = createAction(
  '[setSelectedCustomerCache] Set selectedCustomerCache',
  props<SelectedCustomerCacheInterface>()
);


export const setTabFilterRef = createAction(
  '[setTabFilterRef] Set setTabFilterRef',
  props<ListTabRefInterface>()
);


export const setTabAssetChooserRef = createAction(
  '[setTabAssetChooserRef] Set setTabAssetChooserRef',
  props<ListTabRefInterface>()
);


export const setUser = createAction(
  '[User] Set setUser',
  props<CurrentUserInfo>()
);


export const setApplySelectedCustomer = createAction(
  '[setApplySelectedCustomer] Set setApplySelectedCustomer',
  props<SelectedCustomerCacheInterface>()
);


export const setSelectedAssets = createAction(
  '[setSelectedAssets] Set setSelectedAssets',
  props<SelectedAssets>()
);



export const setApplySelectedAssets = createAction(
  '[setApplySelectedAssets] Set applySelectedAssets',
  props<SelectedAssets>()
);



export const setSelectedPediod= createAction(
  '[setSelectedPediod] Set setSelectedPediod',
  props<SelectedPeriodFitler>()
);


export const setApplySelectedPediod= createAction(
  '[setApplySelectedPediod] Set setApplySelectedPediod',
  props<SelectedPeriodFitler>()
);

export const setIsApplySelectedAssets = createAction(
  '[setIsApplySelectedAssets] Set setIsApplySelectedAssets',
  props<SelectedAssets>()
)


export const  getCompleteApplyState = createAction(
  '[getCompleteApplyState] get CompleteApplyState',
  props<CompleteApplyState>()
)


export const  setCompleteApplyStateCustomer = createAction(
  '[setCompleteApplyStateCustomer] Set Customer CompleteApplyState',
  props<SelectedCustomerCacheInterface>()
)

export const  setCompleteApplyStateSelectedAssets = createAction(
  '[setCompleteApplyStateSelectedAssets] Set Selected Assets CompleteApplyState',
  props<SelectedAssets>()
)

export const  setCompleteApplyStateSelectedPeriod = createAction(
  '[setCompleteApplyStateSelectedPeriod] Set Selected Period CompleteApplyState',
  props<{applySelectedPeriod:SelectedPeriodFitler}>()
)

export const  setCompleteApplyStateIncludeChildrens = createAction(
  '[setCompleteApplyStateIncludeChildrens] Set Selected Period CompleteApplyState IncludeChildrens',
  props<{ includeChildrens: boolean }>()
)

export const  setCompleteApplyState = createAction(
  '[setCompleteApplyState] Set  CompleteApplyState',
  props<CompleteApplyState>()
)

export const  setFilterRefSetting = createAction(
  '[setFilterRefSetting] Set  setFilterRefSetting',
  props<FilterRefSetting>()
)


export const  setFilterRefSettingIncludeChildrens = createAction(
  '[setFilterRefSettingIncludeChildrens] Set  FilterRef Setting IncludeChildrens',
  props<{ showIncludeChildrens: boolean }>()
)















