<div class="form">
    <div class="nav-item {{ stylesheetString }} item-{{menuItem.label}}"
        [matMenuTriggerFor]="menu"
        #clickMenuTrigger="matMenuTrigger"
        (click)="itemClicked()"
        id="item-{{menuItem.label}}"
        >
        <fa-icon size="lg" *ngIf="getIcon() != null" [icon]="getIcon()"></fa-icon>
        <div class="nav-item-label">{{getLabel() | translate}} </div>
        <!-- #6264 FZ-->
        <!-- <dx-context-menu [dataSource]="dataSource" [width]="'100%'" target="#item-{{menuItem.label}}" (onItemClick)="itemPrincClick(menuItem)" *ngIf="childCount(menuItem) <=0">
        </dx-context-menu> -->
    </div>
    <mat-menu #menu="matMenu" (close)="onSubMenuClose()">
        <div class="menu-item {{ stylesheetString }}" *ngFor="let subItem of getChilds(); let i = index" id="image-{{i}}" (click)="subItemClick(subItem)">
            {{getLabel(subItem.label) | translate}}
            <dx-context-menu [dataSource]="dataSource" [width]="'100%'" target="#image-{{i}}" (onItemClick)="itemClick(subItem)">
            </dx-context-menu>
        </div>
    </mat-menu>

</div>
